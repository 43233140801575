/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import {useState} from "react";
import Cookies from 'js-cookie';
import {SERVER_URL} from "../../Constants";
import Header from "./Header";
import useAuth from "../../auth/useAuth";

function Login() {


    const [loginToken, setLoginToken] = useState("");
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const {state} = useAuth();


    async function login(event) {

        event.preventDefault();

        setSuccessMessage("")
        setErrorMessage("")

        const res = await fetch(SERVER_URL + "/api/getlicenseinfo", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: "{\"licenseKey\":\"" + loginToken + "\"}"
        });
        const json = await res.json();
        if (json.error) {
            setErrorMessage("Incorrect license key")

        } else {
            const expirationDate = new Date(json.expireDate)
            Cookies.set('token', json.key, {expires: expirationDate})
            setSuccessMessage("Login success, you will be redirected in 3 seconds")
        }

        setTimeout(function() {
            window.location.reload(false);
        }, 3000);
    }

    return (
        <div className="outerDiv leading-normal tracking-normal text-indigo-400 bg-cover bg-fixed vh-100">
            <div className="h-full p-6">

                <Header/>

                <div className="w-50 m-auto pt-4 text-center">
                    {errorMessage && errorMessage != "" ? <span className="text-red-700">{errorMessage}</span> : null}
                    {successMessage && successMessage != "" ? <span className="text-green-700">{successMessage}</span> : null}
                    <form className="w-full max-w-sm m-auto">
                        <div className="flex items-center border-b border-cyan-800 py-2">
                            <input
                                onChange={(event) => setLoginToken(event.target.value)}
                                className="appearance-none bg-transparent border-none w-full text-white mr-3 py-1 px-2 leading-tight focus:outline-none"
                                type="text" placeholder="License key" aria-label="License key"/>
                            <button
                                onClick={login}
                                className="flex-shrink-0 hover:bg-dark-purple-700 hover:bg-cyan-800 transition duration-300 ease-in-out rounded text-s border-1 border-cyan-800 border text-white py-1 px-2 rounded">
                                Login
                            </button>
                        </div>
                    </form>
                </div>

                <div className="w-full pt-12 pb-6 text-sm text-center md:text-left">
                    <span className="text-gray-500 no-underline hover:no-underline">&copy; IkaOracle 2023</span>
                </div>
            </div>
        </div>
    )
}

export default Login;