/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import {Button, Form, InputGroup, Modal, Table} from "react-bootstrap";
import {useEffect, useState} from "react";
import {JSON_BUILDINGS, SERVER_URL} from "../../Constants";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Collapse from '@kunukn/react-collapse'
import './../../App.css';
import {BsArrowsExpand} from "react-icons/bs";
import {RxCrossCircled} from "react-icons/rx";
import {IconContext} from "react-icons";
import {notifySuccess} from "../../ToastHelper";

function CityGrowthPlanConfiguration(props) {



    const [newCityGrowthPlanName, setNewCityGrowthPlanName] = useState("");
    const [loadedCityGrowthPlans, setLoadedCityGrowthPlans] = useState([])

    const [selectedCityGrowthPlanId, setSelectedCityGrowthPlanId] = useState(0)
    const [selectedStepId, setSelectedStepId] = useState(0)
    const [selectedBuildingId, setSelectedBuildingId] = useState(0)

        const [selectedNewBuildingId, setSelectedNewBuildingId] = useState(30)
    const [newBuildingLevel, setNewBuildingLevel] = useState(1)
    const [newBuildingPriority, setNewBuildingPriority] = useState(0)
    const [newBuildingQuantity, setNewBuildingQuantity] = useState(1)

    const [showCityGrowthPlan, setShowCityGrowthPlan] = useState(false)
    const [showCityGrowthStep, setShowCityGrowthStep] = useState(false)
    const [showCityGrowthBuilding, setShowCityGrowthBuilding] = useState(false)

    const [showExportModal, setShowExportModal] = useState(false);
    const [exportCode, setExportCode] = useState('');


    const [importCode, setImportCode] = useState('');
    const [importPlanName, setImportPlanName] = useState('');


    const handleClose = () => setShowExportModal(false);
    const handleShow = () => setShowExportModal(true);


    function getStepIndexById(id) {
        if (loadedCityGrowthPlans && loadedCityGrowthPlans[selectedCityGrowthPlanId]) {
            return loadedCityGrowthPlans[selectedCityGrowthPlanId].steps.findIndex(step => step.id == id);
        }
        return 0
    }


    const priorityIdToPriorityName = {
        0: "Low",
        1: "Medium",
        2: "High",

    }

    const notifySuccess = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const notifyFailed = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    function addNewBuilding() {
        if (loadedCityGrowthPlans[selectedCityGrowthPlanId] == null || loadedCityGrowthPlans[selectedCityGrowthPlanId].steps.length === 0) return;

        console.log(JSON_BUILDINGS)
        console.log(selectedNewBuildingId)
        console.log(JSON_BUILDINGS.find(building => building.id === parseInt(selectedNewBuildingId)))
        console.log(JSON_BUILDINGS.find(building => building.id === parseInt(selectedNewBuildingId)).id)

        let json = {
            building:  JSON_BUILDINGS.find(building => building.id === parseInt(selectedNewBuildingId)).name,
            priority: newBuildingPriority,
            level: newBuildingLevel,
            quantity: newBuildingQuantity
        }

        let newLoadedCityGrowthPlans = [...loadedCityGrowthPlans];
        let selectedStep = newLoadedCityGrowthPlans[selectedCityGrowthPlanId].steps[getStepIndexById(selectedStepId)];

        console.log()

        if (newLoadedCityGrowthPlans[selectedCityGrowthPlanId].steps[getStepIndexById(selectedStepId)].buildings.some(building => building.building === JSON_BUILDINGS[selectedNewBuildingId])) {
            notifyFailed("You already have this building in the selected step");
            return;
        }

        if (newBuildingQuantity == 0) {
            notifyFailed("The building quantity can't be at 0");
            return;
        }


        if (newBuildingLevel == 0) {
            notifyFailed("The building level can't be at 0");
            return;
        }
        selectedStep.buildings.push(json);
        setLoadedCityGrowthPlans(newLoadedCityGrowthPlans);

    }

    async function removeCityGrowthPlan() {
        let name = loadedCityGrowthPlans[selectedCityGrowthPlanId].name;

        const res = await fetch(SERVER_URL + "/api/citygrowth/checkcitygrowthplans", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(loadedCityGrowthPlans[selectedCityGrowthPlanId])
        });
        const resultParsed = await res.json();
        if (resultParsed.error) {
            notifyFailed("An error occured")
        } else {
            if (!resultParsed.inUse) {
                let updatedCityGrowthPlans = loadedCityGrowthPlans.filter(plan => plan.name !== name);
                setLoadedCityGrowthPlans(updatedCityGrowthPlans);
                setSelectedCityGrowthPlanId(updatedCityGrowthPlans.length > 0 ? updatedCityGrowthPlans.length - 1 : null)
                notifySuccess("CityGrowthPlan removed")
            } else {
                notifyFailed("CityGrowthPlan couldn't be removed because it is used or server is booting ")
                console.log("Error, An account is using this plan")
            }


        }
    }

    function removeStep() {
        if (selectedCityGrowthPlanId == null || selectedStepId == null) return;

        // Find the index of the selected city growth plan

        // Use filter to create a new array without the selected step
        let updatedSteps = loadedCityGrowthPlans[selectedCityGrowthPlanId].steps.filter(step => step.id !== selectedStepId);

        // Update the loadedCityGrowthPlans array with the new steps array
        let updatedCityGrowthPlan = {...loadedCityGrowthPlans[selectedCityGrowthPlanId], steps: updatedSteps};
        let updatedLoadedCityGrowthPlans = [...loadedCityGrowthPlans];
        updatedLoadedCityGrowthPlans[selectedCityGrowthPlanId] = updatedCityGrowthPlan;
        setLoadedCityGrowthPlans(updatedLoadedCityGrowthPlans);

        let newId = updatedLoadedCityGrowthPlans[selectedCityGrowthPlanId] && updatedLoadedCityGrowthPlans[selectedCityGrowthPlanId].steps.length > 0 ? updatedLoadedCityGrowthPlans[selectedCityGrowthPlanId].steps[0].id : 0
        //let newBuildingId = updatedLoadedCityGrowthPlans[selectedCityGrowthPlanId] && updatedLoadedCityGrowthPlans[selectedCityGrowthPlanId].steps[newId].buildings.length > 0 ?  updatedLoadedCityGrowthPlans[selectedCityGrowthPlanId].steps[newId].buildings[0] : 0
        console.log("new id:" + newId)
        console.log(updatedLoadedCityGrowthPlans)
        // Reset the selected step to null
        setSelectedStepId(newId);
        setSelectedBuildingId(0)
    }

    function removeBuilding(name) {
        if (loadedCityGrowthPlans.length === 0 || selectedStepId === null) return;
        const step = loadedCityGrowthPlans[selectedCityGrowthPlanId].steps[getStepIndexById(selectedStepId)];
        const buildingIndex = step.buildings.findIndex(building => building.building === name);
        step.buildings.splice(buildingIndex, 1);
        setLoadedCityGrowthPlans([...loadedCityGrowthPlans])
    }

    function createNewStep() {
        if (loadedCityGrowthPlans[selectedCityGrowthPlanId] == null) return;
        let id = loadedCityGrowthPlans[selectedCityGrowthPlanId].steps.length;
        let json = {
            id: id,
            settings: {
                shouldRoundUp: false,
                roundUpMaxAmbro: 0,
                shouldPremiumTrade: false
            },
            buildings: []
        }
        let index = loadedCityGrowthPlans.findIndex(a => a.name === loadedCityGrowthPlans[selectedCityGrowthPlanId].name)
        let updatedSteps = [...loadedCityGrowthPlans[index].steps, json]
        let updatedCityGrowthPlans = {...loadedCityGrowthPlans[index], steps: updatedSteps}
        let updatedLoadedCityGrowthPlans = [...loadedCityGrowthPlans.slice(0, index), updatedCityGrowthPlans, ...loadedCityGrowthPlans.slice(index + 1)]
        setLoadedCityGrowthPlans(updatedLoadedCityGrowthPlans)

    }

    function createNewPlan() {

        if (newCityGrowthPlanName.trim().length === 0) {
            notifyFailed("A plan name can't be empty");
            return;
        }

        let json = {
            name: newCityGrowthPlanName,
            steps: [],
            planSettings: {
                shouldMakeNewColony: false,
                shouldOnlyBuildCapital: false
            }
        }

        setLoadedCityGrowthPlans([...loadedCityGrowthPlans, json])

    }

    async function importCityGrowthPlan() {

        const plan = {
            code:importCode,
            name:importPlanName
        }

        const res = await fetch(SERVER_URL + "/api/citygrowth/importCityGrowthPlan", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(plan)
        });

        const json = await res.json();
        if (json.error) {
            notifyFailed("An error occured: " + json.error)
        } else {
            notifySuccess("Plan imported")
            props.updateParent();
        }
    }

    async function exportCityGrowthPlan() {

        const plan = {
            plan:loadedCityGrowthPlans[selectedCityGrowthPlanId]
        }

        const res = await fetch(SERVER_URL + "/api/citygrowth/exportCityGrowthPlan", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(plan)
        });

        const json = await res.json();
        if (json.error) {
            notifyFailed("An error occured: " + json.error)
        } else {
            notifySuccess("Plan exported")
            setExportCode(json.code)
            handleShow()
        }
    }

    useEffect(() => {
        // perform some action based on the updated props
        //getCityGrowthPlanData()
        if (props.cityGrowthPlans) setLoadedCityGrowthPlans(props.cityGrowthPlans)
        console.log(props.cityGrowthPlans)
        console.log("update props")
    }, [props.cityGrowth])

    async function saveCityGrowthPlansData() {

        const res = await fetch(SERVER_URL + "/api/citygrowth/setcitygrowthplans", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(loadedCityGrowthPlans)
        });
        const json = await res.json();
        if (json.error) {
            notifyFailed("An error occured: " + json.error)
        } else {
            notifySuccess("Changes saved")
        }
        props.updateParent();
    }

    useEffect(() => {
        // perform some action based on the updated props
        //getCityGrowthPlanData()
        if (props.cityGrowthPlans) setLoadedCityGrowthPlans(props.cityGrowthPlans)
        console.log(props.cityGrowthPlans)
        console.log("update props")
    }, [props.cityGrowth])

    return (
        <div>
            <ToastContainer/>
            <div className={"border-2 border-custom-orange rounded-lg p-2 my-1"}>
                <button className={"flex items-center justify-center bg-custom-orange rounded-lg p-1 mb-2"}
                        onClick={() => setShowCityGrowthPlan(!showCityGrowthPlan)}>
                    <span className="mr-2">City growth plan configuration</span>
                    <BsArrowsExpand/>
                </button>
                <Collapse isOpen={showCityGrowthPlan}>
                    <Form>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="selectedPlanName">City plan name</InputGroup.Text>
                            <Form.Control id="newPlanName" aria-describedby="selectedPlanName"
                                          as="select"
                                          onChange={(event) => setSelectedCityGrowthPlanId(loadedCityGrowthPlans.findIndex(a => a.name == event.target.value))}
                                          value={loadedCityGrowthPlans[selectedCityGrowthPlanId] ? loadedCityGrowthPlans[selectedCityGrowthPlanId].name : ""}>
                                {
                                    loadedCityGrowthPlans.map((cityPlan) => {
                                        return (<option key={cityPlan.name}
                                                        value={cityPlan.name}>{cityPlan.name}</option>)
                                    })
                                }
                            </Form.Control>
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Checkbox
                                aria-label="shouldMakeNewColony"
                                checked={loadedCityGrowthPlans[selectedCityGrowthPlanId] && loadedCityGrowthPlans[selectedCityGrowthPlanId].planSettings.shouldMakeNewColony ? loadedCityGrowthPlans[selectedCityGrowthPlanId].planSettings.shouldMakeNewColony : false}
                                onChange={(event) => {
                                    let newLoadedCityGrowthPlans = [...loadedCityGrowthPlans];
                                    newLoadedCityGrowthPlans[selectedCityGrowthPlanId].planSettings.shouldMakeNewColony = event.target.checked;
                                    setLoadedCityGrowthPlans(newLoadedCityGrowthPlans);
                                }} aria-describedby="shouldMakeNewColonyLabel"

                                disabled={!loadedCityGrowthPlans[selectedCityGrowthPlanId]}
                            />
                            <Form.Control
                                aria-label="ShouldMakeNewColony"
                                value={"Should make new colony"}

                                disabled
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Checkbox
                                aria-label="ShouldOnlyBuildCapital"
                                checked={loadedCityGrowthPlans[selectedCityGrowthPlanId] && loadedCityGrowthPlans[selectedCityGrowthPlanId].planSettings.shouldOnlyBuildCapital ? loadedCityGrowthPlans[selectedCityGrowthPlanId].planSettings.shouldOnlyBuildCapital : false}
                                onChange={(event) => {
                                    let newLoadedCityGrowthPlans = [...loadedCityGrowthPlans];
                                    newLoadedCityGrowthPlans[selectedCityGrowthPlanId].planSettings.shouldOnlyBuildCapital = event.target.checked;
                                    setLoadedCityGrowthPlans(newLoadedCityGrowthPlans);
                                }} aria-describedby="ShouldOnlyBuildCapitalLabel"

                                disabled={!loadedCityGrowthPlans[selectedCityGrowthPlanId]}
                            />
                            <Form.Control
                                aria-label="ShouldOnlyBuildCapital"
                                value={"Should only build capital"}

                                disabled
                            />
                        </InputGroup>
                    </Form>
                    <Table style={{margin: '10px'}} striped bordered hover>
                        <thead>
                        <tr>
                            <th>Step</th>
                            <th>shouldPremiumTrade</th>
                            <th>shouldRoundUp</th>
                            <th>RoundUpMaxAmbro</th>
                            <th>buildings</th>

                        </tr>
                        </thead>
                        <tbody>{loadedCityGrowthPlans[selectedCityGrowthPlanId] ? loadedCityGrowthPlans[selectedCityGrowthPlanId].steps.map((value, i) => {
                            return <tr key={value.id}>
                                <td>{value.id}</td>
                                <td>{value.settings.shouldPremiumTrade.toString()}</td>
                                <td>{value.settings.shouldRoundUp.toString()}</td>
                                <td>{value.settings.roundUpMaxAmbro}</td>
                                {value.buildings.map((building) => {
                                    return <td>{building.building} - {building.level}</td>
                                })}
                            </tr>
                        }) : null}
                        </tbody>
                    </Table>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="newPlanNameLabel">City plan name</InputGroup.Text>
                        <Form.Control id="newPlanName" aria-describedby="newPlanNameLabel"
                                      onChange={(e) => setNewCityGrowthPlanName(e.target.value)}
                                      value={newCityGrowthPlanName}/>
                    </InputGroup>

                    <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                            onClick={createNewPlan}>Create new plan
                    </button>
                    <button className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded mx-2"
                            onClick={removeCityGrowthPlan}>Remove current city growth plan
                    </button>
                </Collapse>
            </div>
            <div className={"border-2 border-custom-orange rounded-lg p-2 my-1"}>
                <button className={"bg-custom-orange rounded-lg p-1 flex items-center justify-center mb-2"}
                        onClick={() => setShowCityGrowthStep(!showCityGrowthStep)}>
                    <span className="mr-2">City growth step configuration</span>
                    <BsArrowsExpand/>
                </button>
                <Collapse isOpen={showCityGrowthStep}>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="selectedPlanName">Step index</InputGroup.Text>
                        <Form.Control as="select"
                                      onChange={(event) => {
                                          setSelectedStepId(loadedCityGrowthPlans[selectedCityGrowthPlanId].steps[getStepIndexById(event.target.value)].id)
                                          setSelectedBuildingId(0);
                                      }}
                                      value={selectedStepId}>
                            {
                                loadedCityGrowthPlans[selectedCityGrowthPlanId] ? loadedCityGrowthPlans[selectedCityGrowthPlanId].steps.map((value) => {
                                    return (<option key={value.id} value={value.id}>{value.id}</option>)
                                }) : null
                            }
                        </Form.Control>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="roundUpMaxAmbroLabel">RoundUpMaxAmbro</InputGroup.Text>
                        <Form.Control type={"number"} id="roundUpMaxAmbro" aria-describedby="roundUpMaxAmbroLabel"
                                      onChange={(event) => {
                                          let newLoadedCityGrowthPlans = [...loadedCityGrowthPlans];
                                          newLoadedCityGrowthPlans[selectedCityGrowthPlanId].steps[getStepIndexById(selectedStepId)].settings.roundUpMaxAmbro = event.target.value;
                                          setLoadedCityGrowthPlans(newLoadedCityGrowthPlans);
                                      }}
                                      value={loadedCityGrowthPlans[selectedCityGrowthPlanId] && loadedCityGrowthPlans[selectedCityGrowthPlanId].steps && loadedCityGrowthPlans[selectedCityGrowthPlanId].steps[getStepIndexById(selectedStepId)] ? loadedCityGrowthPlans[selectedCityGrowthPlanId].steps[getStepIndexById(selectedStepId)].settings.roundUpMaxAmbro : 0}/>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Checkbox
                            aria-label="shouldRoundUp"
                            checked={loadedCityGrowthPlans[selectedCityGrowthPlanId] && loadedCityGrowthPlans[selectedCityGrowthPlanId].steps && loadedCityGrowthPlans[selectedCityGrowthPlanId].steps[selectedStepId] ? loadedCityGrowthPlans[selectedCityGrowthPlanId].steps[selectedStepId].settings.shouldRoundUp : false}
                            onChange={(event) => {
                                let newLoadedCityGrowthPlans = [...loadedCityGrowthPlans];
                                newLoadedCityGrowthPlans[selectedCityGrowthPlanId].steps[getStepIndexById(selectedStepId)].settings.shouldRoundUp = event.target.value;
                                setLoadedCityGrowthPlans(newLoadedCityGrowthPlans);
                            }}
                        />
                        <Form.Control
                            aria-label="shouldRoundUp"
                            value={"Should round up with ambrosia for palace or governor residence"}

                            disabled
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Checkbox
                            aria-label="shouldPremiumTrade"
                            checked={loadedCityGrowthPlans[selectedCityGrowthPlanId] && loadedCityGrowthPlans[selectedCityGrowthPlanId].steps && loadedCityGrowthPlans[selectedCityGrowthPlanId].steps[selectedStepId] ? loadedCityGrowthPlans[selectedCityGrowthPlanId].steps[getStepIndexById(selectedStepId)].settings.shouldPremiumTrade : false}
                            onChange={(event) => {
                                let newLoadedCityGrowthPlans = [...loadedCityGrowthPlans];
                                newLoadedCityGrowthPlans[selectedCityGrowthPlanId].steps[getStepIndexById(selectedStepId)].settings.shouldPremiumTrade = event.target.value;
                                setLoadedCityGrowthPlans(newLoadedCityGrowthPlans);
                            }}
                        />
                        <Form.Control
                            aria-label="shouldPremiumTrade"
                            value={"Should premium trade with ambrosia for palace or governor residence"}
                            disabled
                        />
                    </InputGroup>

                    <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                            onClick={createNewStep}>Create new step
                    </button>
                    <button className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded mx-2"
                            onClick={removeStep}>Remove current step
                    </button>
                </Collapse>
            </div>
            <div className={"border-2 border-custom-orange rounded-lg p-2 my-1"}>
                <button className={"bg-custom-orange rounded-lg p-1 flex items-center justify-center mb-2"}
                        onClick={() => setShowCityGrowthBuilding(!showCityGrowthBuilding)}>
                    <span className="mr-2">City growth building configuration</span>
                    <BsArrowsExpand/>
                </button>

                <Collapse isOpen={showCityGrowthBuilding}>
                    <Table style={{margin: '10px'}} striped bordered hover>
                        <thead>
                        <tr>
                            <th>Building</th>
                            <th>Level required</th>
                            <th>Priority</th>
                            <th>Quantity</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>{loadedCityGrowthPlans[selectedCityGrowthPlanId] && loadedCityGrowthPlans[selectedCityGrowthPlanId].steps.length > 0 && loadedCityGrowthPlans[selectedCityGrowthPlanId].steps[getStepIndexById(selectedStepId)].buildings.length > 0 ? loadedCityGrowthPlans[selectedCityGrowthPlanId].steps[getStepIndexById(selectedStepId)].buildings.map((building) => {
                            return <tr>
                                <td>{building.building}</td>
                                <td>{building.level}</td>
                                <td>{priorityIdToPriorityName[building.priority]}</td>
                                <td>{building.quantity}</td>
                                <td>
                                    <IconContext.Provider value={{size: "26px", color: "red"}}>
                                        <RxCrossCircled onClick={() => removeBuilding(building.building)}
                                                        className={"m-1 hover:cursor-pointer"}/>
                                    </IconContext.Provider>
                                </td>
                            </tr>
                        }) : null}
                        </tbody>
                    </Table>
                    <Form>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="selectedNewBuildingLabel">City building</InputGroup.Text>
                            <Form.Control id="newBuildingName" aria-describedby="selectedNewBuildingLabel" as="select"
                                          onChange={(event) => {
                                              setSelectedNewBuildingId(event.target.value)
                                          }}
                                          value={selectedNewBuildingId}>
                                {JSON_BUILDINGS
                                    .filter(building => !building.hideInPlanConfig)  // Filter out the buildings with hideInPlanConfig: true
                                    .map((building, i) => (
                                        <option key={building.id} value={building.id}>
                                            {building.name}
                                        </option>
                                    ))}
                            </Form.Control>
                        </InputGroup>
                    </Form>

                    <InputGroup className="mb-3">
                        <InputGroup.Text id="newBuildingLevelLabel">New building level</InputGroup.Text>
                        <Form.Control id="newBuildingLevel" aria-describedby="newBuildingLevelLabel"
                                      type="number"
                                      onChange={(e) => setNewBuildingLevel(e.target.value)}
                                      value={newBuildingLevel}/>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="newBuildingPriorityLabel">New priority level</InputGroup.Text>
                        <Form.Control
                            id="newBuildingPriority"
                            aria-describedby="newBuildingPriorityLabel"
                            as="select"
                            onChange={(e) => setNewBuildingPriority(e.target.value)}
                            value={newBuildingPriority}
                        >
                            <option value={0}>Low</option>
                            <option value={1}>Medium</option>
                            <option value={2}>High</option>
                        </Form.Control>
                    </InputGroup>

                    {
                        JSON_BUILDINGS.find(building => building.id === parseInt(selectedNewBuildingId)) && JSON_BUILDINGS.find(building => building.id === parseInt(selectedNewBuildingId)).maxQuantity > 1 && (
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="newBuildingQuantityLabel">New quantity</InputGroup.Text>
                                <Form.Control id="newBuildingQuantity" aria-describedby="newBuildingQuantityLabel"
                                              type="number"
                                              onChange={(e) => setNewBuildingQuantity(e.target.value)}
                                              value={newBuildingQuantity}/>
                            </InputGroup>
                        )}
                    <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                            onClick={addNewBuilding}>Add new building
                    </button>



                </Collapse>
            </div>
            <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                    onClick={saveCityGrowthPlansData}>Save changes
            </button>
            {loadedCityGrowthPlans[selectedCityGrowthPlanId] && <div>
                <button className="bg-green-500 hover:bg-green-600 mt-2 text-white font-bold py-2 px-4 rounded"
                        onClick={exportCityGrowthPlan}>Export plan: {loadedCityGrowthPlans[selectedCityGrowthPlanId].name}
                </button>
            </div>}
            <InputGroup className="mt-2 mb-3">
                <InputGroup.Text id="importCode">Import code</InputGroup.Text>
                <Form.Control id="importCodeInput" aria-describedby="importCode"
                              type="text"
                              onChange={(e) => setImportCode(e.target.value)}
                              value={importCode}/>
            </InputGroup>
            <InputGroup className="mt-2 mb-3">
                <InputGroup.Text id="importPlanName">Import plan name</InputGroup.Text>
                <Form.Control id="importPlanNameInput" aria-describedby="importPlanName"
                              type="text"
                              onChange={(e) => setImportPlanName(e.target.value)}
                              value={importPlanName}/>
            </InputGroup>
            <button className="bg-green-500 hover:bg-green-600 mt-2 text-white font-bold py-2 px-4 rounded"
                    onClick={importCityGrowthPlan}>Import plan
            </button>
            <Modal show={showExportModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>City growth plan export code</Modal.Title>
                </Modal.Header>
                <Modal.Body className={"cursor-pointer"} onClick={() => {
                    navigator.clipboard.writeText(exportCode).then(
                        () => {
                            notifySuccess("Export code copied");
                        },
                        (err) => {
                            notifySuccess('Failed to copy text: ', err);
                        }
                    );
                }}>{exportCode}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CityGrowthPlanConfiguration;