/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import {Table} from "react-bootstrap";
import {useEffect, useState} from "react";
import Layout from "../Layout";
import {SERVER_URL} from "../Constants";
import {IconContext} from "react-icons";
import {MdNotStarted, MdOutlineLogout, MdPauseCircle} from "react-icons/md";
import CustomToolTip from "../CustomToolTip";
import {toast} from "react-toastify";


function MilitaryCheck() {

    const [militaryData, setMilitaryData] = useState([]);

    async function getData() {
        const jsonMission = {
            missionId: 5
        }
        const res = await fetch(SERVER_URL + "/api/mission/getmissions", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonMission)
        });
        const json = await res.json();
        if (json.error) {

        } else {

            setMilitaryData(json)
        }
    }

    async function setMissionData(militaryObject) {
        const jsonMission = {
            accountId: militaryObject.accountId,
            action: !militaryObject.isEnabled,
            missionId: 5
        }
        const res = await fetch(SERVER_URL + "/api/mission/setmission", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonMission)
        });
        return await res.json();
    }

    async function logoutAccount(military) {

        const jsonObject = {
            accountId: military.accountId,
        }
        const res = await fetch(SERVER_URL + "/api/account/logoutaccount", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonObject)
        });
        let json = await res.json();
        if(json.error){
            notifyFailed("An error occured: " + json.error)
        }else{
            notifySuccess("Account logged out")
            getData();
        }
    }

    const notifySuccess = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const notifyFailed = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });


    // function getPirateIndexFromList(pirateId) {
    //     let index = 0;
    //     console.log(pirateId)
    //     for (const element of pirateData) {
    //         console.log(element)
    //         if (element.accountId === pirateId) {
    //             return index
    //         }
    //         index++;
    //     }
    //     return -1;
    // }

    function handleOnClick(index) {
        if (index === -1) return;
        const res = setMissionData(militaryData[index])
        if (res.error) {
            console.log("Error while updating")
            return;
        }
        const newMilitary = [...militaryData];
        newMilitary[index].isEnabled = !newMilitary[index].isEnabled;
        setMilitaryData(newMilitary);

    }

    useEffect(() => {
        console.log("trigger")
        getData()
    }, []);

    return (
        <div>
            <Layout>
                <div className={"p-7"}>
                <p>Military</p>
                <table className=" min-w-max w-full table-auto rounded-t">
                    <thead>
                    <tr className="bg-custom-orange text-gray-600 uppercase text-sm leading-normal">
                        <th className="py-3 text-center">Email</th>
                        <th className="py-3 text-center">Account name</th>
                        <th className="py-3 text-center">Server infos</th>
                        <th className="py-3 text-center">Alliance name</th>
                        <th className="py-3 text-center">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {militaryData.map((military, i) => {
                        return <tr>
                            {military.notifications.length > 0 ?
                                <td>
                                    <div className="p-1 flex items-center justify-center">
                                    <CustomToolTip text={military.notifications}/>
                                    {military.email}
                                    </div>
                                </td> : <td>
                                    {military.email}
                                </td>}
                            <td>{military.accountName}</td>
                            <td>{military.serverInfo}</td>
                            <td>{military.allianceName}</td>
                            <td className="py-3 px-6 text-center whitespace-nowrap">
                                <div className="flex items-center justify-center">
                                    {military.isEnabled ?
                                        <IconContext.Provider value={{size: "26px", color: "green"}}>
                                            <MdPauseCircle onClick={() => setMissionData(military)}
                                                           className={"m-1 hover:cursor-pointer"}/>
                                        </IconContext.Provider> : null}

                                    <IconContext.Provider value={{size: "26px", color: "red"}}>
                                        {military.isEnabled ? null :
                                            <MdNotStarted onClick={() => setMissionData(military)}
                                                          className={"m-1 hover:cursor-pointer"}/>}
                                        <MdOutlineLogout onClick={() => logoutAccount(military)}
                                                         className={"m-1 hover:cursor-pointer"}/>
                                    </IconContext.Provider>
                                </div>
                            </td>
                        </tr>
                    })}
                    </tbody>
                </table>
                </div>
            </Layout>
        </div>
    )
}

export default MilitaryCheck