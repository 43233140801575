import React, {useEffect, useState} from "react";
import { Modal, Button } from "react-bootstrap";
import {CiWarning} from "react-icons/ci";
import {RiDragMoveLine} from "react-icons/ri";

function AuthTokenModal(props) {

    const [urlWithToken,setUrlWithToken] = useState("")
    const [token,setToken] = useState("")

    useEffect(() => {
        if (props.cityGrowth && props.cityGrowth.serverInfo && props.cityGrowth.authToken) {
            console.log(parseString(props.cityGrowth.serverInfo, props.cityGrowth.authToken))
            setUrlWithToken(parseString(props.cityGrowth.serverInfo, props.cityGrowth.authToken));
            setToken(props.cityGrowth.authToken);
        }
    }, [props.cityGrowth]);

    const parseString = (input, token) => {
        if (!input || !token) {
            throw new Error("Invalid inputs provided to parseString.");
        }

        const [lang, serverId] = input.split("_");
        if (!lang || !serverId) {
            throw new Error("Invalid input format. Expected 'LANG_SERVERID'.");
        }

        return `https://s${serverId}-${lang}.ikariam.gameforge.com/?view=city&authToken=${token}`;
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(urlWithToken).then(() => {
            alert("Link copied to clipboard!");
        });
    };

    const openInNewTab = () => {
        window.location.href = urlWithToken;
    };

    return (
        <>
            <Modal
                size="lg"
                show={props.showModal}
                onHide={props.closeModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Open the account in a web browser</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex align-items-center">
                        <CiWarning color={"orange"} size={36} className="mr-2"/>
                        <p><b>The account will open in your own browser, the IkaOracle proxy won't be effective, make
                            sure
                            your
                            environment is set as you want before opening it</b></p>
                    </div>

                    <p className={"mt-4"}>Bookmark the blue button below by dragging it onto your favorite bar</p>

                    <a
                        href="javascript:(function(){const urlParams=new URLSearchParams(window.location.search);const authToken=urlParams.get('authToken');if(authToken){const domainParts=window.location.hostname.split('.');const domain=domainParts.length>2?domainParts.slice(-2).join('.'):window.location.hostname;document.cookie=`ikariam=${authToken};path=/;domain=${domain};secure`;urlParams.delete('authToken');const cleanUrl=`${window.location.origin}${window.location.pathname}?${urlParams.toString()}`;window.location.href=cleanUrl.endsWith('?')?cleanUrl.slice(0,-1):cleanUrl;}else{alert('No authToken found in the URL.');}})()"
                        className="mt-1 bg-blue-500 text-white px-4 py-2 rounded-md flex items-center hover:bg-blue-600"
                        onClick={(e) => e.preventDefault()} // Prevent default navigation behavior
                    >
                        <RiDragMoveLine className="mr-2"/>
                        <p>IkaOracle External Login</p>
                    </a>


                    <p className={"mt-1"}>Once you open the link in your browser, click on the newly created
                        bookmark</p>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className={"bg-custom-orange hover:bg-orange-700 text-white font-medium py-2 px-4 rounded-lg"}
                        onClick={openInNewTab}>Open in a new tab
                    </button>
                    <button
                        className={"bg-custom-orange hover:bg-orange-700 text-white font-medium py-2 px-4 rounded-lg"}
                        onClick={copyToClipboard}><a href={urlWithToken} target="_blank"></a>Copy the link
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AuthTokenModal;
