/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import {Spinner} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import Layout from "../Layout";
import {SERVER_URL} from "../Constants";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactFlagsSelect from "react-flags-select";
import * as ToastHelper from "../ToastHelper";
import {notifyFailed, notifySuccess} from "../ToastHelper";
import {FaSpinner} from "react-icons/fa";
import './../App.css';
import {FiSettings} from "react-icons/fi";
import {MdOutlineLogin, MdOutlineLogout} from "react-icons/md"; // Create this CSS file for custom styles
import {IconContext} from "react-icons";
import LoginInvalidAccountConfiguration from "../component/login/LoginInvalidAccountConfiguration";
import TreeDropdownWithFlagsClick from "../component/TreeDropdownWithFlagsClicks";

function AccountLogin({notifySuccessProps}) {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [accountName, setAccountName] = useState('');
    const [mission, setMission] = useState(1);
    const [selectedServer, setSelectedServer] = useState('');
    const [loginHistoryData, setLoginHistoryData] = useState([])
    const [invalidAccountsData, setInvalidAccountsData] = useState([])
    const [invalidAccount, setInvalidAccount] = useState()
    const [requestProcessing, setRequestProcessing] = useState(false)
    const [serverData, setServerData] = useState([])
    const [showConfig, setShowConfig] = useState(false)
    const [useCustomProxy, setUseCustomProxy] = useState(false);
    const [proxyIp, setProxyIp] = useState("");
    const [proxyPort, setProxyPort] = useState("");
    const [proxyUsername, setProxyUsername] = useState("");
    const [proxyPassword, setProxyPassword] = useState("");
    const toastMap = {};

    const handleServerSelect = (server) => {
        const country = server.split("_")[0];
        const parsedCountry = countryCodeToLanguage[country] || country.toLowerCase()
        const parsedServerInfo = parsedCountry + "_" + server.split("_")[1];
        setSelectedServer(parsedServerInfo);
    };

    const languageToCountryCode = {
        en: "GB", // Map "en" to "GB" for the United Kingdom
    };

    const countryCodeToLanguage = {
        gb: "en", // Map "en" to "GB" for the United Kingdom
    };

    const countries = [...new Set(serverData.map(server => languageToCountryCode[server.language] || server.language.toUpperCase()))];
    const [selectedCountry, setSelectedCountry] = useState('');
    const [filteredServers, setFilteredServers] = useState([]);

    const handleCountryChange = (event) => {
        const country = event
        setSelectedCountry(country);
        const parsedCountry = countryCodeToLanguage[country] || country.toLowerCase()
        setFilteredServers(serverData.filter(server => server.language === parsedCountry));
        setSelectedServer(''); // Reset the selected server
    };

    useEffect(() => {
        getIkariamServers()
        getInvalidAccounts()
    }, []);

    const handleEmailChange = event => {
        setEmail(event.target.value);
    };

    const handleCheckboxChange = () => {
        setUseCustomProxy(!useCustomProxy);
    };

    const handlePasswordChange = event => {
        setPassword(event.target.value);
    };

    const handleAccountNameChange = event => {
        setAccountName(event.target.value);
    };

    const handleMissionChange = event => {
        setMission(event.target.value);
    };


    async function handleLogin() {
        let jsonCredentials;

        if (email.trim().length === 0) {
            ToastHelper.notifyFailed("Email can't be empty");
            return;
        }

        if (password.trim().length === 0) {
            ToastHelper.notifyFailed("Password can't be empty");
            return;
        }

        if (accountName.trim().length === 0) {
            ToastHelper.notifyFailed("Account name can't be empty");
            return;
        }

        if (selectedServer.trim().length === 0) {
            ToastHelper.notifyFailed("You must choose a server");
            return;
        }

        jsonCredentials = {
            email: email,
            accountName: accountName,
            password: password,
            serverInfos: selectedServer,
            missionId: mission
        };

        if (useCustomProxy) {

            if((proxyIp.trim().length === 0 || proxyPort.trim().length === 0)){
                ToastHelper.notifyFailed("Custom proxy IP or Port is empty");
                return;
            }

            // Check if either username or password is provided
            const isUsernameEmpty = proxyUsername.trim().length === 0;
            const isPasswordEmpty = proxyPassword.trim().length === 0;

            // Allow no username/password, but if one is provided, both must be provided
            if ((isUsernameEmpty || isPasswordEmpty) && !(isUsernameEmpty && isPasswordEmpty)) {
                ToastHelper.notifyFailed("Custom proxy username or password is empty");
                return;
            }


            let proxyInfo = proxyIp + ":" + proxyPort;

            if(!isPasswordEmpty || !isPasswordEmpty){
                proxyInfo += ":" + proxyUsername + ":" + proxyPassword
            }

            jsonCredentials.proxyInfo = proxyInfo; // Add the new field

        }

        const loadingToast = toast.info('Logging in ' + accountName + ":" + selectedServer + " ...", {
            icon: <FaSpinner className="spinner"/>, // Custom spinner icon
            hideProgressBar: true,
            autoClose: 180000,
            pauseOnHover: false,
            position: "top-right",
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });

        const toastKey = accountName + ":" + selectedServer;
        toastMap[toastKey] = loadingToast;

        console.log('Initial Toast Map:', toastMap);

        try {
            const res = await fetch(SERVER_URL + "/api/account/accountLogin", {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify(jsonCredentials),
            });
            const json = await res.json();

            console.log('API Response:', json);

            if (json.error) {
                if (toastMap[json.accountNameAndServerInfos]) {
                    toast.update(toastMap[json.accountNameAndServerInfos], {
                        render: json.accountNameAndServerInfos + " => error: " + json.error,
                        type: toast.TYPE.ERROR,
                        autoClose: 5000,
                        hideProgressBar: false,
                        theme: "dark",
                    });
                } else {
                    console.error('Toast key not found in map for error:', json.accountNameAndServerInfos);
                }
            } else {
                if (toastMap[json.accountNameAndServerInfos]) {
                    toast.update(toastMap[json.accountNameAndServerInfos], {
                        render: json.accountNameAndServerInfos + " => success: the account has been logged in",
                        type: toast.TYPE.SUCCESS,
                        autoClose: 5000,
                        hideProgressBar: false,
                        theme: "dark",
                    });
                } else {
                    console.error('Toast key not found in map for success:', json.accountNameAndServerInfos);
                }
            }

            delete toastMap[json.accountNameAndServerInfos];
        } catch (error) {
            console.error('Fetch error:', error);
            toast.update(loadingToast, {
                render: 'An error occurred during the login process',
                type: toast.TYPE.ERROR,
                autoClose: 5000,
                hideProgressBar: false,
                theme: "dark",
            });
        } finally {
            setRequestProcessing(false);
            console.log('Final Toast Map:', toastMap);
        }
    }

    async function handleInvalidLogin(invalidAccount) {
        let jsonCredentials;

        jsonCredentials = {
            email: invalidAccount.email,
            accountName: invalidAccount.accountName,
            password: invalidAccount.password,
            serverInfos: invalidAccount.serverInfos,
            accountId:invalidAccount.accountId,
            missionId: invalidAccount.missionId
        };

        const loadingToast = toast.info('Logging in ' + invalidAccount.accountName + ":" + invalidAccount.serverInfos + " ...", {
            icon: <FaSpinner className="spinner"/>, // Custom spinner icon
            hideProgressBar: true,
            autoClose: 180000,
            pauseOnHover: false,
            position: "top-right",
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });

        const toastKey = invalidAccount.accountName + ":" + invalidAccount.serverInfos;
        toastMap[toastKey] = loadingToast;

        console.log('Initial Toast Map:', toastMap);

        try {
            const res = await fetch(SERVER_URL + "/api/account/invalidaccountLogin", {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify(jsonCredentials),
            });
            const json = await res.json();

            console.log('API Response:', json);

            if (json.error) {
                if (toastMap[json.accountNameAndServerInfos]) {
                    toast.update(toastMap[json.accountNameAndServerInfos], {
                        render: json.accountNameAndServerInfos + " => error: " + json.error,
                        type: toast.TYPE.ERROR,
                        autoClose: 5000,
                        hideProgressBar: false,
                        theme: "dark",
                    });
                } else {
                    console.error('Toast key not found in map for error:', json.accountNameAndServerInfos);
                }
            } else {
                if (toastMap[json.accountNameAndServerInfos]) {
                    toast.update(toastMap[json.accountNameAndServerInfos], {
                        render: json.accountNameAndServerInfos + " => success: the account has been logged in",
                        type: toast.TYPE.SUCCESS,
                        autoClose: 5000,
                        hideProgressBar: false,
                        theme: "dark",
                    });
                } else {
                    console.error('Toast key not found in map for success:', json.accountNameAndServerInfos);
                }
            }

            delete toastMap[json.accountNameAndServerInfos];
        } catch (error) {
            console.error('Fetch error:', error);
            toast.update(loadingToast, {
                render: 'An error occurred during the login process',
                type: toast.TYPE.ERROR,
                autoClose: 5000,
                hideProgressBar: false,
                theme: "dark",
            });
        } finally {
            setRequestProcessing(false);
            console.log('Final Toast Map:', toastMap);
        }
    }



    async function handleLogin2() {

        let jsonCredentials

        if (email.trim().length === 0) {
            ToastHelper.notifyFailed("Email can't be empty")
            return;
        }

        if (password.trim().length === 0) {
            ToastHelper.notifyFailed("Password can't be empty")
            return;
        }

        if (accountName.trim().length === 0) {
            ToastHelper.notifyFailed("Password can't be empty")
            return;
        }

        jsonCredentials = {
            email: email,
            accountName: accountName,
            password: password,
            serverInfos: selectedServer,
            missionId: mission,
        }

        const loadingToast = toast.info('Logging in ' + accountName + ":" + selectedServer + " ...", {
            hideProgressBar: true,
            autoClose: 180000,
            pauseOnHover: false,
            position: "top-right",
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });

        toastMap[accountName + ":" + selectedServer] = loadingToast;

        console.log(toastMap)

        const res = await fetch(SERVER_URL + "/api/account/accountLogin", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonCredentials)
        });
        const json = await res.json();

        if (json.error) {
            toast.update(toastMap[json.accountNameAndServerInfos], {
                render: json.accountNameAndServerInfos + " => error: " + json.error,
                type: toast.TYPE.ERROR,
                autoClose: 5000,
                hideProgressBar: false,
                theme: "dark",
            });
            //notifyFailed("An error occurred during the login of the account")
        } else {
            toast.update(toastMap[json.accountNameAndServerInfos], {
                render: json.accountNameAndServerInfos + " => success: the account has been logged in",
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                theme: "dark",
            });
            //notifySuccess("Success the account has been logged in")
        }
        console.log()
        delete toastMap[json.accountNameAndServerInfos]
        setRequestProcessing(false)

    }

    function borderExample() {
        return <Spinner animation="border"/>;
    }

    async function getInvalidAccounts() {
        const res = await fetch(SERVER_URL + "/api/account/getinvalidaccounts", {
            method: 'GET',
            credentials: 'include',
        });
        const json = await res.json();
        if (json.error) {
            return;
        }
        setInvalidAccountsData(json);
    }

    async function getIkariamServers() {
        const res = await fetch(SERVER_URL + "/api/account/getIkariamServers", {
            method: 'GET',
            credentials: 'include',
        });
        const json = await res.json();
        if (json.error) {
            return;
        }
        setServerData(json);
    }

    async function getLoginHistory() {
        const res = await fetch(SERVER_URL + "/api/lobby/getloginhistory", {
            method: 'GET',
            credentials: 'include',
        });
        const json = await res.json();

        if (json.error) {
            return;
        }
        setLoginHistoryData(json)
    }

    const handleServerChange = (event) => {
        setSelectedServer(event.target.value);
    };

    function timeStampToDate(timestamp) {
        const formatter = new Intl.DateTimeFormat('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
        });
        const formattedTimestamp = formatter.format(new Date(timestamp));
        return formattedTimestamp;
    }

    function closeModal() {
        setShowConfig(false)
    }

    function openModal() {
        setShowConfig(true)
    }

    async function logoutAccount(invalidAccount) {

        const jsonObject = {
            accountId: invalidAccount.accountId,
        }
        const res = await fetch(SERVER_URL + "/api/account/logoutinvalidaccount", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonObject)
        });
        let json = await res.json();
        if(json.error){
            notifyFailed("An error occured: " + json.error)
        }else{
            notifySuccess("Account logged out")
            getInvalidAccounts()
        }

    }


    return (
        <div>
            <Layout>
                <ToastContainer/>
                <div className="min-h-screen flex items-center justify-center bg-gray-100 p-6">
                    <form className="w-full max-w-xl bg-white shadow-md rounded-lg p-6 space-y-6">
                        <h2 className="text-xl font-semibold text-gray-800 text-center">
                            Login Account
                        </h2>

                        {/* Email and Password */}
                        <div className="space-y-4">
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Email
                                </label>
                                <input
                                    disabled={requestProcessing}
                                    type="email"
                                    onChange={handleEmailChange}
                                    value={email}
                                    placeholder={"email"}
                                    className="mt-1 block w-full h-10 px-3 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm"
                                />
                            </div>

                            <div>
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                    Password
                                </label>
                                <input
                                    type="password"
                                    disabled={requestProcessing}
                                    onChange={handlePasswordChange}
                                    value={password}
                                    placeholder={"password"}
                                    className="mt-1 block w-full h-10 px-3 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm"
                                />
                            </div>
                        </div>

                        {/* Account Name */}
                        <div>
                            <label htmlFor="accountName" className="block text-sm font-medium text-gray-700">
                                Account Name
                            </label>
                            <input
                                type="text"
                                disabled={requestProcessing}
                                onChange={handleAccountNameChange}
                                value={accountName}
                                placeholder={"Account name"}
                                className="mt-1 block w-full h-10 px-3 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm"
                            />
                        </div>


                        {/* Server and Mission */}

                            <TreeDropdownWithFlagsClick onServerSelect={handleServerSelect}/>
                            <div>
                                <label htmlFor="mission" className="block text-sm font-medium text-gray-700">
                                    Mission
                                </label>
                                <select
                                    id="mission"
                                    className="mt-1 block w-full h-10 px-3 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm"
                                    disabled={requestProcessing}
                                    onChange={(event) => {
                                        handleMissionChange(event)
                                    }} value={mission}
                                    >
                                    <option value={1}>Pirate</option>
                                    <option value={3}>Island check</option>
                                    <option value={5}>Military check</option>
                                    <option disabled={true} value={6}>City teleport</option>
                                    <option value={7}>City growth</option>
                                </select>
                            </div>


                        {/* Proxy Section */}
                        <div className="border-t pt-4 space-y-4">
                            <div className="flex items-center">
                                <input
                                    type="checkbox"
                                    id="useCustomProxy"
                                    checked={useCustomProxy}
                                    onChange={handleCheckboxChange}
                                    className="mr-2 h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                />
                                <label htmlFor="useCustomProxy" className="text-gray-700 text-sm">
                                    Use Custom Proxy
                                </label>
                            </div>

                            <div className="space-y-4">
                                <div>
                                    <label htmlFor="proxyIp" className="block text-sm font-medium text-gray-700">
                                        Proxy IP
                                    </label>
                                    <input

                                        value={proxyIp}
                                        onChange={(e) => setProxyIp(e.target.value)}
                                        disabled={!useCustomProxy}
                                        type="text"
                                        id="proxyIp"
                                        placeholder="Enter proxy IP"
                                        className="mt-1 block w-full h-10 px-3 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm disabled:opacity-50"
                                    />
                                </div>

                                <div>
                                    <label htmlFor="proxyPort" className="block text-sm font-medium text-gray-700">
                                        Proxy Port
                                    </label>
                                    <input
                                        value={proxyPort}
                                        onChange={(e) => setProxyPort(e.target.value)}
                                        disabled={!useCustomProxy}
                                        type="text"
                                        id="proxyPort"
                                        placeholder="Enter proxy port"

                                        className="mt-1 block w-full h-10 px-3 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm disabled:opacity-50"
                                    />
                                </div>

                                <div>
                                    <label htmlFor="proxyUsername" className="block text-sm font-medium text-gray-700">
                                        Proxy Username (Optional)
                                    </label>
                                    <input
                                        value={proxyUsername}
                                        onChange={(e) => setProxyUsername(e.target.value)}
                                        disabled={!useCustomProxy}
                                        type="text"
                                        id="proxyUsername"
                                        placeholder="Enter username"

                                        className="mt-1 block w-full h-10 px-3 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm disabled:opacity-50"
                                    />
                                </div>

                                <div>
                                    <label htmlFor="proxyPassword" className="block text-sm font-medium text-gray-700">
                                        Proxy Password (Optional)
                                    </label>
                                    <input
                                        value={proxyPassword}
                                        onChange={(e) => setProxyPassword(e.target.value)}
                                        disabled={!useCustomProxy}
                                        type="password"
                                        id="proxyPassword"
                                        placeholder="Enter password"
                                        className="mt-1 block w-full h-10 px-3 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm disabled:opacity-50"
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Submit Button */}
                        <div>
                            <button
                                disabled={requestProcessing}
                                onClick={event => {
                                    event.preventDefault()
                                    handleLogin()}
                                }
                                type="submit"
                                className="w-full h-12 bg-orange-500 hover:bg-orange-600 text-white font-medium text-sm rounded-md focus:outline-none focus:ring-4 focus:ring-orange-300"
                            >
                                Login to Lobby
                            </button>
                        </div>
                    </form>
                </div>
                <br/>
                <span>Invalid accounts (couldn't log in)</span>
                <LoginInvalidAccountConfiguration showModal={showConfig} invalidAccount={invalidAccount} openModal={openModal}
                                          closeModal={closeModal}/>
                <div>
                    <table className=" min-w-max w-full table-auto rounded-t">
                        <thead>
                        <tr className="bg-custom-orange text-gray-600 uppercase text-sm leading-normal">
                            <th className="py-3 text-center">Email</th>
                            <th className="py-3 text-center">Account name</th>
                            <th className="py-3 text-center">Password</th>
                            <th className="py-3 text-center">Server name</th>
                            <th className="py-3 text-center">Mission</th>
                            <th className="py-3 text-center">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {invalidAccountsData.map((invalidAccount, i) => {
                            return <tr>
                                <td className="py-3 px-6 text-center whitespace-nowrap">{invalidAccount.email}</td>
                                <td className="py-3 px-6 text-center whitespace-nowrap">{invalidAccount.accountName}</td>
                                <td className="py-3 px-6 text-center whitespace-nowrap">{invalidAccount.password}</td>
                                <td className="py-3 px-6 text-center whitespace-nowrap">{invalidAccount.serverName}</td>
                                <td className="py-3 px-6 text-center whitespace-nowrap">{invalidAccount.missionName}</td>
                                <td className="py-3 px-6 text-center whitespace-nowrap">
                                    <div className="flex items-center justify-center">
                                        <IconContext.Provider value={{size: "26px", color: "green"}}>
                                            <MdOutlineLogin onClick={() => handleInvalidLogin(invalidAccount)}
                                                             className={"m-1 hover:cursor-pointer"}/>
                                        </IconContext.Provider>
                                        <IconContext.Provider value={{size: "26px", color: "red"}}>
                                            <MdOutlineLogout onClick={() => logoutAccount(invalidAccount)}
                                                             className={"m-1 hover:cursor-pointer"}/>
                                        </IconContext.Provider>
                                        <IconContext.Provider value={{size: "26px", color: "gray"}}>
                                            <FiSettings
                                                className={"m-1 hover:cursor-pointer"}
                                                onClick={() => {
                                                    setInvalidAccount(invalidAccount)
                                                    setShowConfig(!showConfig)
                                                }}
                                            />
                                        </IconContext.Provider>
                                    </div>

                                </td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                </div>
            </Layout>
        </div>
    )
}

export default AccountLogin;