/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Logo3 from './assets/logo.png'
import Control from './assets/control.png'
import {GiIsland, GiPirateFlag, GiTeleport} from "react-icons/gi"
import {AiFillDashboard, AiFillSetting, AiOutlineLogin, AiOutlinePlusCircle} from "react-icons/ai"
import {MdAdminPanelSettings, MdLocationSearching, MdOutlineLogout} from "react-icons/md"
import {FaCity} from "react-icons/fa"
import {IconContext} from "react-icons";
import './App.css'
import Cookies from 'js-cookie';
import useAuth from "./auth/useAuth";
import {MENUS} from "./Constants";

const Sidebar = () => {

  const [open, setOpen] = useState(true);
  const [selectedIndex,setSelectedIndex] = useState(-1)
  const location = useLocation();
  const {state} = useAuth();



  useEffect(() => {
    const selectedIndex = MENUS.findIndex(item => item.url === location.pathname);
    setSelectedIndex(selectedIndex !== -1 ? selectedIndex : 0);
  }, [location.pathname]);


  return (
    <div className="flex">
      <div
        className={` ${
          open ? "w-72" : "w-20 "
        } bg-dark-purple h-screen custom-p5 pt-8 relative duration-300`}
      >
        <img
          src={Control}
          className={`absolute cursor-pointer -right-3 top-9 w-7 border-dark-purple
           border-2 rounded-full  ${!open && "rotate-180"}`}
          onClick={() => setOpen(!open)}
        />
        <div className="flex gap-x-4 items-center">
          <img
            src={Logo3}
            width={"90px"}
            className={`cursor-pointer duration-500 ${
              open && "rotate-[360deg]"
            }`}
          />
          <h1
            className={`text-white origin-left font-medium text-xl duration-200 ${
              !open && "scale-0"
            }`}
          >
            IkaOracle
          </h1>
        </div>
        <ul className="pt-6">
          {MENUS.map((Menu, index) => (
              !Menu.adminOnly || (Menu.adminOnly && state.licenseInfo.isAdmin) ?
              <Link key={index} to={ !Menu.permission || (Menu.permission && state.licenseInfo[Menu.permission]) ? Menu.url : ''}><li
              key={index}
              onClick={() =>
              {
                if(Menu.title === "Logout") {
                  Cookies.remove("token")
                  setTimeout(function() {
                    window.location.reload(false);
                  }, 500);
                }
              }}
              className={`flex rounded-md p-2   text-gray-300 font-semibold text-sm items-center gap-x-4 
              ${Menu.gap ? "mt-9" : "mt-2"} ${index === selectedIndex ? "bg-light-white" : ""} ${!Menu.permission || (Menu.permission && state.licenseInfo[Menu.permission]) ? "cursor-pointer hover:bg-light-white" : "cursor-default"}`}
            >
              <IconContext.Provider value={{ size:"26px", color: !Menu.permission || (Menu.permission && state.licenseInfo[Menu.permission]) ? "orange" : "gray" , className: "global-class-name" }}>
                <Menu.src/>
              </IconContext.Provider>
              <span className={`${!open && "hidden"} origin-left duration-200`}>
                {Menu.title}
              </span>
            </li></Link> : null
          ))}
        </ul>
      </div>
      <div className="h-screen flex-1">
      </div>
    </div>
  );
};
export default Sidebar;
