/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import {JSON_BUILDINGS, SERVER_URL} from "../../Constants";
import transporter from './../../assets/cities/transporters.PNG'
import freighter from './../../assets/cities/freighters.PNG'
import goldIcon from './../../assets/cities/icon_gold.PNG'
import populationIcon from './../../assets/cities/icon_population.png'
import medalIcon from './../../assets/cities/medal.png'
import favourIcon from './../../assets/cities/favour.png'
import scientistIcon from './../../assets/cities/icon_scientist.png'
import researchPointIcon from './../../assets/cities/icon_research.png'
import researchPointPerHourIcon from './../../assets/cities/icon_research_time.png'

import woodIcon from './../../assets/cities/icon_wood.png'
import wineIcon from './../../assets/cities/icon_wine.png'
import marbleIcon from './../../assets/cities/icon_marble.png'
import crystalIcon from './../../assets/cities/icon_glass.png'
import sulfurIcon from './../../assets/cities/icon_sulfur.png'

import poseidonIcon from './../../assets/cities/poseidon.png'
import hephaestusIcon from './../../assets/cities/hephaestus.png'
import hadesIcon from './../../assets/cities/hades.png'
import aresIcon from './../../assets/cities/ares.png'
import demeterIcon from './../../assets/cities/demeter.png'
import athenaIcon from './../../assets/cities/athena.png'
import hermesIcon from './../../assets/cities/hermes.png'
import colossusIcon from './../../assets/cities/colossus.png'

import {GiUpgrade} from "react-icons/gi";

import '../../App.css'

import {IconContext} from "react-icons";

function CityGrowthInformation(props) {

    const [cityGrowthInfo, setCityGrowthInfo] = useState([])
    const [showBuildings, setShowBuildings] = useState(true)
    const [selectedCity, setSelectedCity] = useState();
    const [selectedCityData, setSelectedCityData] = useState();

    function getResourceIconById(id) {
        switch (id) {
            case 0:
                return woodIcon;
            case 1:
                return wineIcon;
            case 2:
                return marbleIcon;
            case 3:
                return crystalIcon;
            case 4:
                return sulfurIcon;
            default:
                return null; // or a default icon if you have one
        }
    }

// Function to get god icon by ID
    function getGodIconById(id) {
        switch (id) {
            case 1:
                return hephaestusIcon;
            case 2:
                return hadesIcon;
            case 3:
                return demeterIcon;
            case 4:
                return athenaIcon;
            case 5:
                return hermesIcon;
            case 6:
                return aresIcon;
            case 7:
                return poseidonIcon;
            case 8:
                return colossusIcon;
            default:
                return null; // or a default icon if you have one
        }
    }

    const chunkArray = (array, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    };

    const chunkedBuildings = chunkArray(JSON_BUILDINGS, 10);

    function formatNumberWithCommas(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    function getUpgradeIcon(totalLevel, building) {
        const json = JSON.parse(JSON.stringify(building))
        if (json[0].building.includes("constructionSite")) {
            return (
                <IconContext.Provider value={{color: "#90d5ff", style: {verticalAlign: "middle"}}}>
            <span style={{display: "flex", alignItems: "center", gap: "0.5rem"}}>
                {totalLevel} <GiUpgrade/>
            </span>
                </IconContext.Provider>
            )
        } else if (json[0].canUpgrade) {
            return (
                <IconContext.Provider value={{color: "green", style: {verticalAlign: "middle"}}}>
            <span style={{display: "flex", alignItems: "center", gap: "0.5rem"}}>
                {totalLevel} <GiUpgrade/>
            </span>
                </IconContext.Provider>
            )
        } else {
            return (
                <IconContext.Provider value={{color: "red", style: {verticalAlign: "middle"}}}>
            <span style={{display: "flex", alignItems: "center", gap: "0.5rem"}}>
                {totalLevel} <GiUpgrade/>
            </span>
                </IconContext.Provider>
            )
        }

    }

    useEffect(() => {
        if (props.cityGrowth) {
            setCityGrowthInfo(props.cityGrowth.accountInformation.cityArrayInformation)
            setSelectedCity(props.cityGrowth.accountInformation.cityArrayInformation[0]?.id)
            setSelectedCityData(props.cityGrowth.accountInformation.cityArrayInformation.find(city => city.id === props.cityGrowth.accountInformation.cityArrayInformation[0]?.id))
        }
    }, [props.cityGrowth])

    return (
        <div>
            <Modal
                size="xl"
                show={props.showModal}
                onHide={props.closeModal}
            >
                <ul className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row" role="tablist">
                    <li className="-mb-px mr-2 last:mr-0 flex-auto text-center hover:cursor-pointer">
                        <a
                            className={
                                "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                                (showBuildings === true
                                    ? "text-white bg-custom-orange"
                                    : "text-custom-orange bg-white")
                            }
                            onClick={e => {
                                e.preventDefault();
                                setShowBuildings(true);
                            }}
                        >
                            Buildings
                        </a>
                    </li>
                    <li className="-mb-px mr-2 last:mr-0 flex-auto text-center hover:cursor-pointer">
                        <a
                            className={
                                "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                                (showBuildings === false
                                    ? "text-white bg-custom-orange"
                                    : "text-custom-orange bg-white")
                            }
                            onClick={e => {
                                e.preventDefault();
                                setShowBuildings(false);
                            }}
                        >
                            Information
                        </a>
                    </li>
                </ul>
                <div className="border-custom-orange border-solid border-2 p-4 rounded-lg">
                    {showBuildings ? (
                        <div>
                            {chunkedBuildings.map((chunk, chunkIndex) => (
                                <table key={chunkIndex}>
                                    <thead>
                                    <tr>
                                        <th></th>
                                        {chunk.map((building) => (
                                            !building.hide && (
                                                <th key={building.id} className="p-2">
                                                    <img src={building.src} alt={building.name}
                                                         className="table-image"/>
                                                </th>
                                            )
                                        ))}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {props.cityGrowth ? props.cityGrowth.accountInformation.cityArrayInformation.map((city, rowIndex) => (
                                        <tr key={rowIndex}>
                                            <td className="p-2">{city.name}</td>
                                            {chunk.map((building, colIndex) => {
                                                if (building.hide) return null;
                                                const totalLevel = city.buildings
                                                    .filter(b => b.buildingType === building.name)
                                                    .reduce((acc, b) => acc + b.level, 0);
                                                const currentBuilding = city.buildings
                                                    .filter(b => b.buildingType === building.name);
                                                return (
                                                    <td className="p-2 text-center" key={colIndex}>
                                                        {totalLevel > 0 ? getUpgradeIcon(totalLevel, currentBuilding) : "-"}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    )) : null}
                                    </tbody>
                                </table>
                            ))}
                        </div>
                    ) : (
                        <div>
                            <div>
                                <div className="my-4 p-4 bg-gray-100 rounded-lg shadow-lg">
                                    <h3 className="text-xl font-semibold mb-4">
                                        <i>{props.cityGrowth ? props.cityGrowth.accountName : ""}</i> Overall Account
                                        Information:</h3>
                                    <table className="w-full text-sm text-left table-auto">
                                        <tbody>
                                        <tr className="border-b">
                                            <td className="py-2 pr-4 font-medium">
                                                <img src={favourIcon} alt="Favour"
                                                     className="inline-block w-6 h-6 mr-2"/>
                                                Favour:
                                            </td>
                                            <td className="py-2 pl-4">{props.cityGrowth.accountInformation.favour}</td>
                                        </tr>
                                        <tr className="border-b">
                                            <td className="py-2 pr-4 font-medium">
                                                <img src={freighter} alt="Freighters"
                                                     className="inline-block w-6 h-6 mr-2"/>
                                                Free Freighters:
                                            </td>
                                            <td className="py-2 pl-4">
                                                {props.cityGrowth.accountInformation.freeFreighters} (
                                                {props.cityGrowth.accountInformation.maxFreighters})
                                            </td>
                                        </tr>
                                        <tr className="border-b">
                                            <td className="py-2 pr-4 font-medium">
                                                <img src={transporter} alt="Transporters"
                                                     className="inline-block w-6 h-6 mr-2"/>
                                                Free Transporters:
                                            </td>
                                            <td className="py-2 pl-4">
                                                {props.cityGrowth.accountInformation.freeTransporters} (
                                                {props.cityGrowth.accountInformation.maxTransporters})
                                            </td>
                                        </tr>
                                        <tr className="border-b">
                                            <td className="py-2 pr-4 font-medium">
                                                <img src={goldIcon} alt="Gold" className="inline-block w-6 h-6 mr-2"/>
                                                Gold:
                                            </td>
                                            <td className="py-2 pl-4">
                                                {formatNumberWithCommas(props.cityGrowth.accountInformation.gold)}
                                            </td>
                                        </tr>
                                        <tr className="border-b">
                                            <td className="py-2 pr-4 font-medium">
                                                <img src={medalIcon} alt="Total Score"
                                                     className="inline-block w-6 h-6 mr-2"/>
                                                Total Score:
                                            </td>
                                            <td className="py-2 pl-4">{props.cityGrowth.accountInformation.totalScore}</td>
                                        </tr>
                                        <tr className="border-b">
                                            <td className="py-2 pr-4 font-medium">
                                                <img src={scientistIcon} alt="Scientists"
                                                     className="inline-block w-6 h-6 mr-2"/>
                                                Scientists:
                                            </td>
                                            <td className="py-2 pl-4">{props.cityGrowth.accountInformation.scientists}</td>
                                        </tr>
                                        <tr className="border-b">
                                            <td className="py-2 pr-4 font-medium">
                                                <img src={researchPointIcon} alt="Research Points"
                                                     className="inline-block w-6 h-6 mr-2"/>
                                                Research Points:
                                            </td>
                                            <td className="py-2 pl-4">{props.cityGrowth.accountInformation.researchPoints}</td>
                                        </tr>
                                        <tr>
                                            <td className="py-2 pr-4 font-medium">
                                                <img src={researchPointPerHourIcon} alt="Research Points Per Hour"
                                                     className="inline-block w-6 h-6 mr-2"/>
                                                Research Points Per Hour:
                                            </td>
                                            <td className="py-2 pl-4">{props.cityGrowth.accountInformation.researchPointsPerHour}</td>
                                        </tr>
                                        </tbody>
                                    </table>

                                    <br/>
                                    <h3 className="text-xl font-semibold mb-4">Researches</h3>

                                    <div className="overflow-x-auto">
                                        <table className="min-w-full table-auto border-collapse">
                                            <thead>
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="border px-4 py-2"
                                                >
                                                    Seafaring
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="border px-4 py-2"
                                                >
                                                    Economy
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="border px-4 py-2"
                                                >
                                                    Science
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="border px-4 py-2"
                                                >
                                                    Military
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="border px-4 py-2"
                                                >
                                                    Mythology
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td className="border px-4 py-2">
                                                    {props.cityGrowth.accountInformation.researches.seafaring}
                                                </td>
                                                <td className="border px-4 py-2">
                                                    {props.cityGrowth.accountInformation.researches.economy}
                                                </td>
                                                <td className="border px-4 py-2">
                                                    {props.cityGrowth.accountInformation.researches.knowledge}
                                                </td>
                                                <td className="border px-4 py-2">
                                                    {props.cityGrowth.accountInformation.researches.military}
                                                </td>
                                                <td className="border px-4 py-2">
                                                    {props.cityGrowth.accountInformation.researches.mythology}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br/>
                                    <h3 className="text-xl font-semibold mb-4">Cities information</h3>
                                    <div className="overflow-x-auto">
                                        <table className="min-w-full table-auto border-collapse">
                                            <thead>
                                            <tr>
                                                <th className="border px-4 py-2">Name</th>
                                                <th className="border px-4 py-2"><img src={populationIcon}/></th>
                                                <th className="border px-4 py-2"><img src={woodIcon}/></th>
                                                <th className="border px-4 py-2"><img src={wineIcon}/></th>
                                                <th className="border px-4 py-2"><img src={marbleIcon}/></th>
                                                <th className="border px-4 py-2"><img src={crystalIcon}/></th>
                                                <th className="border px-4 py-2"><img src={sulfurIcon}/></th>
                                                <th className="border px-4 py-2">Coordinates</th>
                                                <th className="border px-4 py-2">Wonder</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {props.cityGrowth.accountInformation.cityArrayInformation.map((city, index) => (
                                                <tr key={index}>
                                                    <td className="border px-4 py-2">
                                                    <span className="inline-block mr-2 align-middle">
                                                        <img src={getResourceIconById(city.tradeGoodType)}
                                                             alt="trade good"/>
                                                    </span>
                                                        <span className="inline-block align-middle">{city.name}</span>
                                                    </td>
                                                    <td className="border px-4 py-2">
                                                        {city.totalPopulation} ({city.citizen})
                                                    </td>
                                                    <td className="border px-4 py-2">{city.wood}</td>
                                                    <td className="border px-4 py-2">{city.wine}</td>
                                                    <td className="border px-4 py-2">{city.marble}</td>
                                                    <td className="border px-4 py-2">{city.crystal}</td>
                                                    <td className="border px-4 py-2">{city.sulfur}</td>
                                                    <td className="border px-4 py-2">{city.coords}</td>
                                                    <td className="border px-4 py-2">
                                                        <img width={48} height={48}
                                                             src={getGodIconById(city.wonderType)}
                                                             alt="wonder type"/>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    )
}

export default CityGrowthInformation