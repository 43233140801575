/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import Sidebar from "./Sidebar";
import {useEffect, useState} from "react";
import {MENUS, SERVER_URL} from "./Constants";
import {GrCircleInformation} from 'react-icons/gr'
import {toast, ToastContainer} from "react-toastify";
import React from "react";
import useAuth from "./auth/useAuth";
import {AiFillDashboard, AiFillSetting, AiOutlineLogin, AiOutlinePlusCircle} from "react-icons/ai";
import {GiIsland, GiPirateFlag, GiTeleport} from "react-icons/gi";
import {MdAdminPanelSettings, MdLocationSearching, MdOutlineLogout} from "react-icons/md";
import {FaCity} from "react-icons/fa";
import {Link} from "react-router-dom";

const Layout = ({children}) => {
    const [open, setOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [version, setVersion] = useState("");
    const {state} = useAuth();

    async function getLoadingData() {

        const res = await fetch(SERVER_URL + "/api/lobby/getstatus", {
            method: 'GET',
            credentials: 'include',
        });
        const json = await res.json();
        if (json.error) {
            return;
        }
        setLoading(json.isLoading)
    }

    async function getVersion() {

        const res = await fetch(SERVER_URL + "/api/version/getversion", {
            method: 'GET',
            credentials: 'include',
        });
        const json = await res.json();
        if (json.error) {
            return;
        }
        setVersion("v"+json.version)
    }

    function checkPermission() {

        const index = MENUS.findIndex(menu => menu.url === window.location.pathname)

        if (index === -1) return false;

        if (MENUS[index].permission) {
            const perm = MENUS[index].permission
            return state.licenseInfo[perm]
        }

        return true;
    }

    useEffect(() => {
        checkPermission()
        getLoadingData()
getVersion()
    }, [])

    return (
        <div className="h-screen flex flex-col">
            <div className="flex-1 flex">
                <Sidebar open={open} setOpen={setOpen}/>
                <div className="flex-grow">
                    {isLoading ? (
                        <div className="inline-block bg-custom-orange rounded-lg p-2">
                            <span className="flex items-center">
                                <GrCircleInformation className="mr-2"/> The server recently rebooted, please wait patiently while every account is logged
                            </span>
                        </div>
                    ) : null}

                    <ToastContainer/>
                    {checkPermission() ? children : <div>
                        <span>Access unauthorized</span>
                        <Link to={"/"}><button className={"bg-custom-orange hover:bg-orange-700 text-white font-medium py-2 px-4 rounded-lg"}>Go back to dashboard</button> </Link>
                    </div>}
                    {/*{React.Children.map(children, (child) => {*/}
                    {/*    if (React.isValidElement(child)) {*/}
                    {/*        return React.cloneElement(child, { notifySuccessProps: true});*/}
                    {/*    }*/}
                    {/*})}*/}
                </div>
            </div>
            <div className="text-center text-white bg-dark-purple py-2 flex-shrink-0">Copyright © 2023 IkaOracle -
                Seth [{version}]
            </div>
        </div>
    );
};

export default Layout;