/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import {useEffect, useState} from "react";
import {SERVER_URL} from "../../Constants";
import {Chart, registerables} from 'chart.js'
import {Bar} from "react-chartjs-2";

Chart.register(...registerables)

function PirateDashboard({handlePirateInformation}) {

    const [pirateStats, setPirateStats] = useState();
    const [barData, setBarData] = useState();

    async function getPirateLog() {

        const res = await fetch(SERVER_URL + "/api/pirate/getpiratelog", {
            method: 'GET',
            credentials: 'include',
        });
        let jsonRes = await res.json();
        if (jsonRes.error) {

        } else {
            setPirateStats(jsonRes)
            setBarData(computeChartData(jsonRes))
            handlePirateInformation(jsonRes.point + ":" + jsonRes.captcha)
        }
    }

    const options = {
        plugins: {
            legend: {
                display: false, // Hides the legend
            },
            tooltip: {
                enabled: true, // Disables tooltips
            },
        },
    };

    function computeChartData(finalData) {
        let processedData = []
        processedData.push(finalData['2:30'])
        processedData.push(finalData['7:30'])
        processedData.push(finalData['15m'])
        processedData.push(finalData['30m'])
        processedData.push(finalData['1h'])
        processedData.push(finalData['2h'])
        processedData.push(finalData['4h'])
        processedData.push(finalData['8h'])
        processedData.push(finalData['16h'])
        const data = {
            labels: ['2:30', '7:30', '15m', '30m', '1h', '2h', '4h', '8h', '16h'],
            datasets: [
                {
                    label: 'Pirate courses',
                    data: processedData,
                    barPercentage: 0.5,
                    barThickness: 6,
                    maxBarThickness: 8,
                    minBarLength: 2,
                }],
            options: {
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            }
        };
        return data;
    }

    function getMostUsedCourse() {
        if (!pirateStats) return ""
        let highestKey;
        let highestValue = 0;
        for (let key in pirateStats) {
            if (key == "captcha" || key == "point" || key == "key" || key == "_id") continue;
            if (!highestKey) {
                highestKey = key
                highestValue = pirateStats[key]
            } else {
                if (highestValue < pirateStats[key]) {
                    highestKey = key
                    highestValue = pirateStats[key]
                }
            }
        }

        const result = highestKey ? `${highestKey} (${highestValue})` : "";
        return result;
    }

    useEffect(() => {
        getPirateLog();
    }, [])

    return (
        <div>
        <h3 className="text-2xl font-bold mb-2">Courses distribution</h3>
        {barData ? <Bar options={options} data={barData}/> : null}
    </div>)
}

export default PirateDashboard