/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import Layout from "../Layout";
import {useEffect, useState} from "react";
import {Doughnut} from "react-chartjs-2";
import {ArcElement, Chart as ChartJS, Tooltip} from 'chart.js';
import useAuth from "../auth/useAuth";
import {SERVER_URL} from "../Constants";
import PirateDashboard from "../component/dashboard/PirateDashboard";
import {FaCalendarAlt, FaChartBar, FaCheck, FaUserAlt} from "react-icons/fa";

ChartJS.register(ArcElement, Tooltip);

function Dashboard() {

    const [doughnutData, setDoughnutData] = useState();

    const [userData, setUserData] = useState();
    const {state} = useAuth();
    const [isLoading, setLoading] = useState();
    const [pointProduced, setPointProduced] = useState(0)
    const [captchaSolved, setCaptchaSolved] = useState(0)
    const [dayLeft, setDayLeft] = useState("")

    const handlePirateInformation = (pirateInfo) => {
        if (pirateInfo.split(":").length != 2) return;

        setPointProduced(pirateInfo.split(":")[0])
        setCaptchaSolved(pirateInfo.split(":")[1])
    };


    const calculateDaysLeft = (targetDate) => {
        const now = new Date();
        const futureDate = new Date(targetDate);

        // Calculate the difference in milliseconds
        const diff = futureDate - now;

        // If the date is in the past, return "Expired"
        if (diff <= 0) {
            return "Expired";
        }

        // Convert milliseconds to days
        return Math.ceil(diff / (1000 * 60 * 60 * 24)) + " days remaining"
    };

    async function getData() {
        const jsonMission = {
            missionId: -1
        };
        const res = await fetch(SERVER_URL + "/api/mission/getmissions", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonMission)
        });
        const json = await res.json();
        let unUsed = state.licenseInfo.maxAccount - json.pirate - json.islandCheck - json.militaryCheck - json.cityTeleport - json.cityGrowth;
        let finalData = [json.pirate, json.islandCheck, json.militaryCheck, json.cityTeleport, json.cityGrowth, unUsed]
        setUserData(finalData);

        // Call computeChartData() and set the returned value as the chartData state
        setDoughnutData(computeChartData(finalData));

        console.log(state)
    }

    const options = {
        plugins: {
            legend: {
                display: false, // Hides the legend
            },
            tooltip: {
                enabled: true, // Disables tooltips
            },
        },
    };

    function computeChartData(finalData) {
        const data = {
            labels: ['Pirate', 'Island check', 'Military check', 'City teleport', 'City growth', 'Accounts unused'],
            datasets: [
                {
                    label: 'Accounts',
                    data: finalData,
                    backgroundColor: [
                        'rgba(0, 255, 0, 0.5)',
                        'rgba(54, 162, 235, 0.5)',
                        'rgba(255, 99, 132, 0.5)',
                        'rgba(125, 99, 132, 0.5)',
                        'rgba(0, 99, 132, 0.5)',
                        'rgba(255, 255, 132, 0.5)',
                    ],
                    borderColor: [
                        'rgba(0, 255, 0, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(125, 99, 132, 1)',
                        'rgba(0, 99, 132, 1)',
                        'rgba(255, 255, 132, 0.5)',
                    ],
                    borderWidth: 1,
                }],
        };
        return data;
    }

    useEffect(() => {
        getData();
        setDayLeft(calculateDaysLeft(state.licenseInfo.expireDate))

    }, []);

    const backgroundClass =
        dayLeft === "Expired"
            ? "bg-gradient-to-r from-red-500 to-red-700"
            : "bg-gradient-to-r from-green-500 to-green-700";

    return (
        <Layout>
            <div className="p-7 grid grid-cols-2 gap-6">
                {/* Top Left: Circular Diagram */}
                <div className="bg-white shadow-lg rounded-lg p-6 relative">
                    <h3 className="text-2xl font-bold mb-2">Account Distribution</h3>
                    <div className="flex justify-center items-center max-h-96">
                        {doughnutData ? (
                            <Doughnut options={options} data={doughnutData}/>
                        ) : (
                            <p>No Data Available</p>
                        )}
                    </div>
                </div>

                {/* Top Right: User Plan and Days Left */}
                <div className="grid grid-rows-2 gap-4">
                    {/* User Plan Card */}
                    <div
                        className="bg-gradient-to-r from-blue-500 to-blue-700 text-white shadow-lg rounded-lg p-6 flex items-center relative">
                        <FaUserAlt className="text-4xl mr-6"/>
                        <div className="flex flex-col justify-between w-full">
                            <h3 className="text-2xl font-bold">User Plan</h3>
                            <p className="text-3xl">Premium Plan</p>
                        </div>
                        {/* SVG Wave Decoration */}
                        <svg
                            className="absolute bottom-0 left-0 w-full h-36 text-blue-300 opacity-60"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="none"
                        >
                            <path
                                d="M20 100 Q 50 20, 100 0 T 100 100 H 0 Z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>

                    {/* Days Left Card */}
                    <div
                        className={`${backgroundClass} text-white shadow-lg rounded-lg p-6 flex items-center relative`}
                    >
                        <FaCalendarAlt className="text-4xl mr-6"/>
                        <div className="flex flex-col justify-between w-full">
                            <h3 className="text-2xl font-bold">Days Left</h3>
                            <p className="text-3xl">{dayLeft}</p>
                        </div>
                        {/* SVG Wave Decoration */}
                        <svg
                            className="absolute bottom-0 left-0 w-full h-36 text-green-300 opacity-60"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="none"
                        >
                            <path
                                d="M20 100 Q 50 20, 100 0 T 100 100 H 0 Z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                </div>

                {/* Bottom Left: Pirate Dashboard (Bar Diagram) */}
                <div className="bg-white shadow-lg rounded-lg p-6 relative">
                    <PirateDashboard handlePirateInformation={handlePirateInformation}/>
                    {/* SVG Wave Decoration */}
                </div>

                {/* Bottom Right: Points Produced and Captchas Solved */}
                <div className="grid grid-rows-2 gap-4">
                    {/* Points Produced Card */}
                    <div
                        className="bg-gradient-to-r from-yellow-400 to-yellow-600 text-white shadow-lg rounded-lg p-6 flex items-center relative">
                        <FaChartBar className="text-4xl mr-6"/>
                        <div className="flex flex-col justify-between w-full">
                            <h3 className="text-2xl font-bold">Points Produced</h3>
                            <div className="flex items-center justify-between w-full">
                                <p className="text-3xl">{pointProduced} points</p>
                            </div>
                        </div>
                        {/* SVG Wave Decoration */}
                        <svg
                            className="absolute bottom-0 left-0 w-full h-36 text-yellow-300 opacity-60"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="none"
                        >
                            <path
                                d="M20 100 Q 50 20, 100 0 T 100 100 H 0 Z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>

                    {/* Captchas Solved Card */}
                    <div
                        className="bg-gradient-to-r from-purple-500 to-purple-700 text-white shadow-lg rounded-lg p-6 flex items-center relative">
                        <FaCheck className="text-4xl mr-6"/>
                        <div className="flex flex-col justify-between w-full">
                            <h3 className="text-2xl font-bold">Captchas Solved</h3>
                            <div className="flex items-center justify-between w-full">
                                <p className="text-3xl">{captchaSolved} captchas</p>
                            </div>
                        </div>
                        {/* SVG Wave Decoration */}
                        <svg
                            className="absolute bottom-0 left-0 w-full h-36 text-purple-300 opacity-60"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="none"
                        >
                            <path
                                d="M20 100 Q 50 20, 100 0 T 100 100 H 0 Z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </Layout>

    )
}

export default Dashboard;