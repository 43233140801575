/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import {Table} from "react-bootstrap";
import {useEffect, useState} from "react";
import useAuth from "../auth/useAuth";
import '../index.css'
import CityGrowthPlanConfiguration from "../component/citygrowth/CityGrowthPlanConfiguration";
import CityGrowthConfiguration from "../component/citygrowth/CityGrowthConfiguration";
import Layout from "../Layout";
import {IconContext} from "react-icons";
import {MdNotStarted, MdOutlineLogout, MdPauseCircle} from "react-icons/md";
import {FiSettings} from "react-icons/fi";
import {SERVER_URL} from "../Constants";
import CustomToolTip from "../CustomToolTip";

import {toast} from "react-toastify";
import {FaEye, FaUserCircle} from "react-icons/fa";
import CityGrowthInformation from "../component/citygrowth/CityGrowthInformation";
import {BiLogIn} from "react-icons/bi";
import CityGrowthExternalLogin from "../component/citygrowth/CityGrowthExternalLogin";

function CityGrowth() {

    const [cityGrowthData, setCityGrowthData] = useState([]);
    const [selectedCityGrowth, setSelectedCityGrowth] = useState();
    const [loadedCityGrowthPlans, setLoadedCityGrowthPlans] = useState()
    const {state} = useAuth();
    const [showPlanConfig, setShowPlanConfig] = useState(false);
    const [showConfig, setShowConfig] = useState(false)
    const [showInformation, setShowInformation] = useState(false)
    const [showExternalLogin, setShowExternalLogin] = useState(false)


    async function getCityGrowthPlanData() {

        const res = await fetch(SERVER_URL + "/api/citygrowth/getcitygrowthplans", {
            method: 'GET',
            credentials: 'include',
        });
        const json = await res.json();

        setLoadedCityGrowthPlans(json)

    }

    function closeExternalLoginModal(event) {
        if (event) event.preventDefault()
        setShowExternalLogin(false)
    }

    function showExternalLoginModal() {
        setShowExternalLogin(true)
    }

    function showInformationModal() {
        setShowInformation(true)
    }

    function closeInformationModal(event) {
        if (event) event.preventDefault()
        setShowInformation(false)
    }

    function showConfigModal() {
        setShowConfig(true)
    }

    function closeConfigModal(event) {
        if (event) event.preventDefault()
        setShowConfig(false)
    }

    async function getData() {
        const jsonMission = {
            missionId: 7
        }
        const res = await fetch(SERVER_URL + "/api/mission/getmissions", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonMission)
        });
        const json = await res.json();
        if (json.error) {
            return;
        }
        setCityGrowthData(json)

    }

    useEffect(() => {
        getData()
        getCityGrowthPlanData()
    }, []);


    async function setMissionData(cityGrowthObject) {
        const jsonMission = {
            accountId: cityGrowthObject.accountId,
            action: !cityGrowthObject.isEnabled,
            missionId: 7
        }


        const res = await fetch(SERVER_URL + "/api/mission/setmission", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonMission)
        });
        return await res.json();
    }

    async function logoutAccount(cityGrowth) {

        const jsonObject = {
            accountId: cityGrowth.accountId,
        }
        const res = await fetch(SERVER_URL + "/api/account/logoutaccount", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonObject)
        });
        let json = await res.json();
        if (json.error) {
            notifyFailed("An error occured: " + json.error)
        } else {
            notifySuccess("Account logged out")
            getData();
        }

    }

    const notifySuccess = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const notifyFailed = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });


    function handleOnClick(cityGrowth) {
        let index = cityGrowthData.findIndex(a => a.accountId === cityGrowth.accountId)
        if (index === -1) return;
        const res = setMissionData(cityGrowthData[index])
        if (res.error) {
            return;
        }
        const newCityGrowth = [...cityGrowthData];
        newCityGrowth[index].isEnabled = !newCityGrowth[index].isEnabled;
        setCityGrowthData(newCityGrowth);

    }


    return (
        <div>
            <Layout>
                <ul
                    className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
                    role="tablist"
                >
                    <li className="-mb-px mr-2 last:mr-0 flex-auto text-center hover:cursor-pointer">
                        <a
                            className={
                                "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                                (showPlanConfig === false
                                    ? "text-white bg-custom-orange"
                                    : "text-custom-orange bg-white")
                            }
                            onClick={e => {
                                e.preventDefault();
                                setShowPlanConfig(false);
                            }}
                        >
                            City growth
                        </a>
                    </li>
                    <li className="-mb-px mr-2 last:mr-0 flex-auto text-center hover:cursor-pointer">
                        <a
                            className={
                                "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                                (showPlanConfig === true
                                    ? "text-white bg-custom-orange"
                                    : "text-custom-orange bg-white")
                            }
                            onClick={e => {
                                e.preventDefault();
                                setShowPlanConfig(true);
                            }}
                        >
                            City growth plan configuration
                        </a>
                    </li>
                </ul>
                {
                    showPlanConfig ? <CityGrowthPlanConfiguration updateParent={getCityGrowthPlanData}
                                                                  cityGrowth={selectedCityGrowth}
                                                                  cityGrowthPlans={loadedCityGrowthPlans}/> :

                        [
                            <CityGrowthExternalLogin showModal={showExternalLogin} cityGrowth={selectedCityGrowth ? selectedCityGrowth : ""}
                                                     openModal={showExternalLoginModal}
                                                     closeModal={closeExternalLoginModal}/>,

                            <CityGrowthInformation showModal={showInformation} cityGrowth={selectedCityGrowth}
                                                   cityGrowthPlans={loadedCityGrowthPlans}
                                                   openModal={showInformationModal}
                                                   closeModal={closeInformationModal}/>,
                            <CityGrowthConfiguration showModal={showConfig} cityGrowth={selectedCityGrowth}
                                                     cityGrowthPlans={loadedCityGrowthPlans}
                                                     openModal={showConfigModal}
                                                     closeModal={closeConfigModal}/>,
                            <div>
                                <table className=" min-w-max w-full table-auto rounded-t">
                                    <thead>
                                    <tr className="bg-custom-orange text-gray-600 uppercase text-sm leading-normal">
                                        <th className="py-3 text-center">Email</th>
                                        <th className="py-3 text-center">Account name</th>
                                        <th className="py-3 text-center">Server infos</th>
                                        <th className="py-3 text-center">Citygrowth plan</th>
                                        <th className="py-3 text-center">Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {cityGrowthData.map((cityGrowth, i) => {
                                        return <tr>
                                            {cityGrowth.notifications.length > 0 ?
                                                <td>
                                                    <div className="p-1 flex items-center justify-center">
                                                        <CustomToolTip text={cityGrowth.notifications}/>
                                                        {cityGrowth.email}
                                                    </div>
                                                </td> : <td className="py-3 px-6 text-center whitespace-nowrap">
                                                    {cityGrowth.email}
                                                </td>}
                                            <td className="py-3 px-6 text-center whitespace-nowrap">{cityGrowth.accountName}</td>
                                            <td className="py-3 px-6 text-center whitespace-nowrap">{cityGrowth.serverInfo}</td>
                                            <td className="py-3 px-6 text-center whitespace-nowrap">{cityGrowth.cityGrowthPlanName === "" ? "none" : cityGrowth.cityGrowthPlanName}</td>
                                            <td className="py-3 px-6 text-center whitespace-nowrap">
                                                <div className="flex items-center justify-center">
                                                    {cityGrowth.isEnabled ?
                                                        <IconContext.Provider value={{size: "26px", color: "green"}}>
                                                            <MdPauseCircle onClick={() => handleOnClick(cityGrowth)}
                                                                           className={"m-1 hover:cursor-pointer"}/>
                                                        </IconContext.Provider> : null}

                                                    <IconContext.Provider value={{size: "26px", color: "red"}}>
                                                        {cityGrowth.isEnabled ? null :
                                                            <MdNotStarted onClick={() => handleOnClick(cityGrowth)}
                                                                          className={"m-1 hover:cursor-pointer"}/>}
                                                        <MdOutlineLogout onClick={() => logoutAccount(cityGrowth)}
                                                                         className={"m-1 hover:cursor-pointer"}/>
                                                    </IconContext.Provider>
                                                    <IconContext.Provider value={{size: "26px", color: "gray"}}>
                                                        <FiSettings
                                                            className={"m-1 hover:cursor-pointer"}
                                                            onClick={() => {
                                                                setSelectedCityGrowth(cityGrowth)
                                                                console.log(cityGrowth)
                                                                showConfigModal()
                                                            }}
                                                        />
                                                        <FaEye
                                                            className={"m-1 hover:cursor-pointer"}
                                                            onClick={() => {
                                                                setSelectedCityGrowth(cityGrowth)
                                                                console.log(cityGrowth)
                                                                showInformationModal()
                                                            }}/>
                                                        <FaUserCircle
                                                            className="m-1 hover:cursor-pointer"
                                                            onClick={() => {
                                                                setSelectedCityGrowth(cityGrowth)
                                                                console.log(cityGrowth)
                                                                showExternalLoginModal()
                                                            }}/>
                                                    </IconContext.Provider>

                                                </div>
                                            </td>
                                        </tr>
                                    })}
                                    </tbody>
                                </table>
                            </div>]
                }

            </Layout>
        </div>
    )
}

export default CityGrowth;