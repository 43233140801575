import React, { useState, useRef, useEffect } from "react";
import ReactFlagsSelect from "react-flags-select";
import { SERVER_URL } from "../Constants";

const formatServersData = (apiResponse) => {
    console.log(apiResponse)

    const formattedData = {};

    apiResponse.forEach((server) => {
        if (server.name.includes("Asphodel")) {
            return;
        }

        let country = server.language.toUpperCase();

        // Adjust the country code for 'EN' to 'GB'
        country = country === "EN" ? "GB" : country;

        const innerItem = { name: server.name, id: server.number };

        if (!formattedData[country]) {
            formattedData[country] = [];
        }

        formattedData[country].push(innerItem);
    });

    console.log(formattedData);
    return formattedData;
};

const TreeDropdownWithFlagsClick = ({ onServerSelect }) => {
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedServer, setSelectedServer] = useState("");
    const [hoveredCountry, setHoveredCountry] = useState("");
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [searchQuery, setSearchQuery] = useState(""); // Track search query
    const submenuTimeout = useRef(null);
    const [formattedServers, setFormattedServers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); // Track the current page for country pagination

    const countriesPerPage = 6; // Set the number of countries per page

    const handleMouseEnter = (countryCode) => {
        clearTimeout(submenuTimeout.current);
        setHoveredCountry(countryCode);
    };

    async function getIkariamServers() {
        const res = await fetch(SERVER_URL + "/api/account/getIkariamServers", {
            method: 'GET',
            credentials: 'include',
        });
        const json = await res.json();
        if (json.error) {
            return;
        }
        return json;
    }

    useEffect( () => {
        async function fetchData(){
            const server = await getIkariamServers();
            setFormattedServers(formatServersData(server));
        }

        fetchData();

    }, []);

    const handleMouseLeave = () => {
        submenuTimeout.current = setTimeout(() => {
            setHoveredCountry("");
        }, 200);
    };

    const toggleDropdown = (e) => {
        e.preventDefault();
        setDropdownVisible(!dropdownVisible);
    };

    const handlePagination = (direction) => {
        setCurrentPage((prevPage) => {
            if (direction === "next") {
                return Math.min(Math.ceil(filteredCountries.length / countriesPerPage), prevPage + 1);
            } else if (direction === "prev") {
                return Math.max(1, prevPage - 1);
            }
        });
    };

    const filteredCountries = Object.keys(formattedServers).filter((countryCode) =>
        countryCode.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const paginatedCountries = filteredCountries.slice(
        (currentPage - 1) * countriesPerPage,
        currentPage * countriesPerPage
    );

    return (
        <div className="relative w-full">
            <label className="block text-sm font-medium text-gray-700">Select Country & Server</label>
            <div className="mt-2 relative">
                {/* Trigger Button */}
                <button
                    className="w-full h-10 px-4 py-2 text-sm bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    onClick={toggleDropdown}
                    aria-haspopup="true"
                >
                    {selectedCountry
                        ? `${selectedCountry} - ${selectedServer || "Select a Server"}`
                        : "Select Country"}
                </button>

                {/* Country and Server Dropdown Menu */}
                {dropdownVisible && (
                    <div className="absolute z-10 mt-2 w-60 bg-white shadow-lg rounded-md border border-gray-200">
                        {/* Search Input */}
                        <div className="px-4 py-2">
                            <input
                                type="text"
                                placeholder="Search countries..."
                                className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </div>

                        {/* Country Selector */}
                        {paginatedCountries.map((countryCode) => (
                            <div
                                key={countryCode}
                                className="relative group"
                                onMouseEnter={() => handleMouseEnter(countryCode)}
                                onMouseLeave={handleMouseLeave}
                            >
                                <div className="flex items-center px-4 py-2 cursor-pointer hover:bg-indigo-50">
                                    <ReactFlagsSelect
                                        selected={countryCode}
                                        countries={[countryCode]}
                                        customLabels={{
                                            [countryCode]: `${countryCode} (${selectedCountry === countryCode ? "Selected" : ""})`,
                                        }}
                                        disabled
                                        className="pointer-events-none"
                                    />
                                </div>

                                {/* Server Submenu */}
                                {hoveredCountry === countryCode && (
                                    <div
                                        className="absolute left-full top-0 ml-1 w-40 bg-white shadow-lg rounded-md border border-gray-200"
                                        onMouseEnter={() => clearTimeout(submenuTimeout.current)}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        {formattedServers[countryCode].map((server) => (
                                            <button
                                                key={server.id} // Use the server ID as the key
                                                className={`block w-full px-4 py-2 text-left text-sm hover:bg-indigo-50 ${
                                                    selectedServer === server.name ? "font-semibold" : ""
                                                }`}
                                                onClick={() => {
                                                    setSelectedCountry(countryCode);
                                                    setSelectedServer(server.name);
                                                    setDropdownVisible(false);
                                                    onServerSelect(countryCode.toLowerCase() + "_" + server.id); // Notify parent of selection
                                                }}
                                            >
                                                {server.name} {/* Display server name */}
                                            </button>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}

                        {/* Pagination Controls */}
                        <div className="flex justify-between px-4 py-2">
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    handlePagination("prev");
                                }}
                                className="text-sm text-gray-700 disabled:opacity-50"
                                disabled={currentPage === 1}
                            >
                                Prev
                            </button>
                            <span className="text-sm text-gray-700">
                                Page {currentPage}
                            </span>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    handlePagination("next");
                                }}
                                className="text-sm text-gray-700 disabled:opacity-50"
                                disabled={currentPage * countriesPerPage >= filteredCountries.length}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TreeDropdownWithFlagsClick;
