/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import {Form, InputGroup, Modal} from "react-bootstrap";
import {useEffect, useState} from "react";
import {SERVER_URL} from "../../Constants";
import {toast} from "react-toastify";

function PirateConfiguration(props) {


    const [desiredCrew, setDesiredCrew] = useState(0)
    const [shouldConvertCrew, setShouldConvertCrew] = useState(false)
    const [piratePointLimit, setPiratePointLimit] = useState(0)
    const [shouldStopAtLimit, setShouldStopAtLimit] = useState(false)
    const [tryToSavePointsWhenAttacked, setTryToSavePointsWhenAttacked] = useState(false)
    const [checkForAttackWhenPaused, setCheckForAttackWhenPaused] = useState(false)


    async function updateData(event) {
        event.preventDefault()

        if (!props.selectedPirate) return;
        let jsonEdit = {
            desiredCrew: desiredCrew,
            shouldConvertCrew: shouldConvertCrew,
            pirateMissionId: selectedMission.id,
            shouldStopAtLimit: shouldStopAtLimit,
            pirateStopLimit: piratePointLimit,
            tryToSavePointsWhenAttacked:tryToSavePointsWhenAttacked,
            checkForAttackWhenPaused:checkForAttackWhenPaused,
            accountId: props.selectedPirate.accountId
        }

        console.log(jsonEdit)

        const res = await fetch(SERVER_URL + "/api/account/setaccount", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonEdit)
        });
        const json = await res.json();
        if(json.error){
            notifyFailed("An error occured: " + json.error)
        }else{
            notifySuccess("Changes saved")
            props.closeModal(event);
            props.updateData()
        }


    }

    const notifySuccess = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const notifyFailed = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });


    useEffect(() => {
        // perform some action based on the updated props
        if (props.selectedPirate) {
            setDesiredCrew(props.selectedPirate.desiredCrew)
            setShouldConvertCrew(props.selectedPirate.shouldConvertCrew)
            setShouldStopAtLimit(props.selectedPirate.shouldStopAtLimit)
            setPiratePointLimit(props.selectedPirate.pirateStopLimit)
            setTryToSavePointsWhenAttacked(props.selectedPirate.tryToSavePointsWhenAttacked)
            setSelectedMission(props.selectedPirate.availableMissions[props.selectedPirate.courseTypeId - 1])
            setCheckForAttackWhenPaused(props.selectedPirate.checkForAttackWhenPaused)
        }
        console.log("update props")
    }, [props.selectedPirate])

    const [selectedMission, setSelectedMission] = useState(null);

    return (
        <div>
            <Modal show={props.showModal} onHide={props.closeModal}>
                <div className="border-custom-orange border-solid border-2 p-4 rounded-lg">
                <h1 className="font-bold text-xl">Pirate configuration</h1>
                <span>{props.selectedPirate ? props.selectedPirate.accountName : "none"}</span>

                <Form>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="selectedMissionLabel">Missions available</InputGroup.Text>
                        <Form.Control
                            id="selectedMission"
                            aria-describedby="selectedMissionLabel"
                            as="select"
                            onChange={(event) => {
                                console.log(props.selectedPirate.availableMissions[event.target.value -1])
                                setSelectedMission(props.selectedPirate.availableMissions[event.target.value -1])
                            }}
                            value={selectedMission !== null ? selectedMission.id : null}
                        >
                            {
                                props.selectedPirate && props.selectedPirate.availableMissions.map((mission) => {
                                    return (<option key={mission.id} value={mission.id}>{mission.timeFormat}</option>)
                                })
                            }
                        </Form.Control>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Checkbox
                            aria-label="shouldConvertCrew"
                            checked={props.selectedPirate ? shouldConvertCrew : false}
                            onChange={(event) => {
                                setShouldConvertCrew(event.target.checked)
                            }}
                        />
                        <Form.Control
                            aria-label="shouldConvertCrew"
                            value={"Should convert crew"}
                            disabled
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="desiredCrewLabel">Desired crew</InputGroup.Text>
                        <Form.Control id="desiredCrew" aria-describedby="desiredCrewLabel"
                                      type={"number"}
                                      disabled={!shouldConvertCrew}
                                      onChange={(event) => {
                                          setDesiredCrew(event.target.value)
                                      }}
                                      value={props.selectedPirate ? desiredCrew : 0}/>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Checkbox
                            aria-label="shouldStopAtLimit"
                            checked={props.selectedPirate ? shouldStopAtLimit : false}
                            onChange={(event) => {
                                setShouldStopAtLimit(event.target.checked)
                            }}
                        />
                        <Form.Control
                            aria-label="shouldStopAtLimit"
                            value={"Should stop at limit"}
                            disabled
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="pirateStopLimit">Pirate point limit</InputGroup.Text>
                        <Form.Control id="pirateStopLimit" aria-describedby="pirateStopLimit"
                                      type={"number"}
                                      disabled={!shouldStopAtLimit}
                                      onChange={(event) => {
                                          setPiratePointLimit(event.target.value)
                                      }}
                                      value={props.selectedPirate ? piratePointLimit : 0}/>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Checkbox
                            aria-label="tryToSavePointsWhenAttacked"
                            checked={props.selectedPirate ? tryToSavePointsWhenAttacked : false}
                            onChange={(event) => {
                                setTryToSavePointsWhenAttacked(event.target.checked)
                            }}
                        />
                        <Form.Control
                            aria-label="tryToSavePointsWhenAttacked"
                            value={"Try to save points when attacked"}
                            disabled
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Checkbox
                            aria-label="checkForAttackWhenPaused"
                            checked={props.selectedPirate ? checkForAttackWhenPaused : false}
                            onChange={(event) => {
                                setCheckForAttackWhenPaused(event.target.checked)
                            }}
                        />
                        <Form.Control
                            aria-label="checkForAttackWhenPaused"
                            value={"Check for attack when paused"}
                            disabled
                        />
                    </InputGroup>

                    <button onClick={updateData}
                            className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-green-700 rounded">Save
                    </button>
                    <button onClick={props.closeModal}
                            className="bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-red-700 rounded">Cancel
                    </button>
                </Form>
                </div>
            </Modal>
        </div>
    )


}

export default PirateConfiguration;