/**
 * COPYRIGHT (c) 2023 IkaOracle
 *
 * All rights reserved. This software and its documentation are confidential and
 * proprietary information of IkaOracle. It may not be used, copied, disclosed, distributed,
 * demonstrated, or licensed except in accordance with a valid written agreement from IkaOracle.
 *
 * Any person obtaining this code should not use, show, distribute or otherwise make it available
 * to any other person or entity without written permission from IkaOracle.
 */

import Layout from "../Layout";
import {useEffect, useState} from "react";
import {IconContext} from "react-icons";
import {Table} from "react-bootstrap";
import {RxCrossCircled} from "react-icons/rx";
import {SERVER_URL} from "../Constants";


function Settings() {

    const [raidWhiteList, setRaidWhiteList] = useState([])
    const [nameToWhiteList, setNameToWhiteList] = useState("")
    const [discordData, setDiscordData] = useState();

    async function saveRaidWhiteListData() {

        const jsonToSend = {
            names: raidWhiteList
        }

        const res = await fetch(SERVER_URL + "/api/raid/setraidwhitelist", {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(jsonToSend)
        });
        let jsonRes = await res.json();
        if (jsonRes.error) {

        }
    }

    async function resetDiscordConfiguration() {

        const res = await fetch(SERVER_URL + "/api/discord/resetconfiguration", {
            method: 'GET',
            credentials: 'include',
        });
        let jsonRes = await res.json();
        if (jsonRes.error) {

        } else {

        }
    }

    async function setDiscordConfiguration() {

        if(!discordData) return;

        const res = await fetch(SERVER_URL + "/api/discord/setconfiguration", {
            method: 'POST',
            credentials: 'include',
            body:JSON.stringify(discordData)
        });
        let jsonRes = await res.json();
        if (jsonRes.error) {

        } else {
            getDiscordConfiguration()
        }
    }


    async function getDiscordConfiguration() {

        const res = await fetch(SERVER_URL + "/api/discord/getconfiguration", {
            method: 'GET',
            credentials: 'include',
        });
        let jsonRes = await res.json();
        if (jsonRes.error) {
            console.log(jsonRes.error)
        } else {
            setDiscordData(jsonRes)
        }
    }

    async function getRaidWhiteListData() {

        const res = await fetch(SERVER_URL + "/api/raid/getraidwhitelist", {
            method: 'GET',
            credentials: 'include',
        });
        let jsonRes = await res.json();
        if (jsonRes.error) {

        } else {
            setRaidWhiteList(jsonRes)
        }
    }

    function removeFromBinds(index) {
        setRaidWhiteList(raidWhiteList.splice(index,1))
    }
    function removeFromWhiteList(index) {
        setRaidWhiteList(raidWhiteList.splice(index,1))
    }

    useEffect(() => {
        getRaidWhiteListData()
        getDiscordConfiguration()
    }, []);

    function addToWhiteList() {
        setRaidWhiteList(prevState => [...prevState, nameToWhiteList])
        setNameToWhiteList("")
    }

    return (
        <div>
            <Layout>
                <div className={"p-7"}>
                <div>Raid Whitelist</div>
                <Table style={{margin: '10px'}} striped bordered hover>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {raidWhiteList.map((name,index) => {
                        return <tr>
                            <td>{name}</td>
                            <td className="py-3 px-6 text-center whitespace-nowrap">
                                <div className="flex items-center justify-center">
                                    <IconContext.Provider value={{size: "26px", color: "red"}}>
                                        <RxCrossCircled onClick={() => removeFromWhiteList(index)}
                                            className={"m-1 hover:cursor-pointer"}/>
                                    </IconContext.Provider>
                                </div>
                            </td>
                        </tr>
                    })}
                    </tbody>
                </Table>
                <label className="block text-gray-700 font-medium mt-4 mb-2">
                    Name to whitelist
                </label>
                <input
                    type="text"
                    className="block w-full border border-gray-400 rounded-lg p-2"
                    onChange={event => setNameToWhiteList(event.target.value)}
                    value={nameToWhiteList}
                    placeholder={"name to whitelist"}
                />
                <button
                    className={"bg-custom-orange hover:bg-orange-700 text-white font-medium py-2 px-4 rounded-lg"}
                    onClick={addToWhiteList}>
                    Add to whitelist
                </button>
                <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                        onClick={saveRaidWhiteListData}>Save changes
                </button>


                <div>
                    <h1>Discord configuration</h1>
                        <div>
                            <p>Server name: {discordData ? discordData.serverName : null}</p>
                            <p>Attack channel name: {discordData ? discordData.attackChannelName : null}</p>
                            <p>Island check channel name: {discordData ? discordData.islandCheckChannelName : null}</p>
                            <p>Raiding channel name: {discordData ? discordData.raidingChannelName : null}</p>
                            <button
                                className={"bg-custom-orange hover:bg-orange-700 text-white font-medium py-2 px-4 rounded-lg"}
                                onClick={resetDiscordConfiguration}>
                                Reset discord configuration

                            </button>
                        </div>
                    <Table style={{margin: '10px'}} striped bordered hover>
                        <thead>
                        <tr>
                            <th>Ikariam account ID</th>
                            <th>Discord user ID</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {discordData ? discordData.binds.map((discord,index) => {
                            return <tr>
                                <td>{discord[0]}</td>
                                <td>{discord[1]}</td>
                                <td className="py-3 px-6 text-center whitespace-nowrap">
                                    <div className="flex items-center justify-center">
                                        <IconContext.Provider value={{size: "26px", color: "red"}}>
                                            <RxCrossCircled onClick={() => removeFromBinds(index)} className={"m-1 hover:cursor-pointer"}/>
                                        </IconContext.Provider>
                                    </div>
                                </td>
                            </tr>
                        }):null}
                        </tbody>
                    </Table>
                    <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                            onClick={setDiscordConfiguration}>Save changes
                    </button>
                </div>
                </div>
            </Layout>
        </div>
    )
}

export default Settings;